import { FC, PropsWithChildren } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import ResumeVideoLink from 'src/components/ResumeVideoLink';

const ResumeVideo: FC<PropsWithChildren> = () => {
    const video = useSelector((state) => state.resume?.videos?.[0] ?? '');
    const resumeId = useSelector((state) => Number(state.resume?.id));

    if (!video) {
        return null;
    }

    return <ResumeVideoLink videoLink={video} resumeId={resumeId} />;
};

export default ResumeVideo;
