import useExperimentCheckFunction, {
    useExperimentControlCheckFunction,
    useExperimentGroupCheckFunction,
} from 'lux/hooks/useExperimentCheckFunction';

export default function (WrappedComponent) {
    const Experiment = (props) => {
        const getExperimentGroup = useExperimentGroupCheckFunction();
        const isExperimentEnabled = useExperimentCheckFunction();
        const isExperimentControlEnabled = useExperimentControlCheckFunction();
        return (
            <WrappedComponent
                {...props}
                getExperimentGroup={getExperimentGroup}
                isExperimentEnabled={isExperimentEnabled}
                isExperimentControlEnabled={isExperimentControlEnabled}
            />
        );
    };
    return Experiment;
}
