import { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import resumeFavoriteButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_favorite_button_click';
import Button from 'bloko/blocks/button';
import Down, { DownLayer, DownPlacement } from 'bloko/blocks/drop/Down';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import {
    StarScaleSmallAppearanceFilledEnclosedFalse,
    StarScaleSmallAppearanceOutlinedEnclosedFalse,
    IconColor,
} from 'bloko/blocks/icon';

import useIsClient from 'lux/hooks/useIsClient';
import { useNotification } from 'src/components/Notifications/Provider';
import ResumeFolders from 'src/components/ResumeFolders';
import putInFolder from 'src/components/ResumeFolders/putResumesFolder';
import translation from 'src/components/translation';

const ResumeFoldersDropDown = ({
    trls,
    hasResumeInFolders,
    simHash,
    employerManagerId,
    resumeHash,
    resumeId,
    vacancyId,
    ...props
}) => {
    const isClient = useIsClient();
    const [menuVisible, setMenuVisible] = useState(false);
    const [addedResumeInFolders, setAddedResumeInFolders] = useState(hasResumeInFolders);
    const { addNotification } = useNotification();

    const onButtonClick = useCallback(() => {
        resumeFavoriteButtonClick({ resumeId, vacancyId });
        setMenuVisible(!menuVisible);
    }, [menuVisible, resumeId, vacancyId]);

    const hideMenu = useCallback(() => setMenuVisible(false), []);
    const onSuccess = useCallback((hasResumeInFolders) => {
        setMenuVisible(false);
        setAddedResumeInFolders(hasResumeInFolders);
    }, []);

    return (
        <Down
            onClose={hideMenu}
            show={menuVisible}
            layer={DownLayer.AboveContent}
            placement={DownPlacement.BottomEnd}
            onlySetPlacement
            render={() => (
                <ResumeFolders
                    dataQAPrefix="resume-serp__favourite-popup"
                    onSave={putInFolder({ onSuccess, simHash, employerManagerId, resumeHash }, addNotification)}
                    {...props}
                />
            )}
        >
            <HoverTip
                host={isClient ? document.body : null}
                layer={TipLayer.Overlay}
                render={() => trls[ResumeFoldersDropDown.trls.tipTitle]}
            >
                <Button
                    data-qa="resume-favorite-button"
                    icon={
                        addedResumeInFolders ? (
                            <StarScaleSmallAppearanceFilledEnclosedFalse
                                data-qa={'favorite-icon-on'}
                                initial={IconColor.Blue60}
                            />
                        ) : (
                            <StarScaleSmallAppearanceOutlinedEnclosedFalse
                                data-qa={'favorite-icon-off'}
                                initial={IconColor.Gray50}
                            />
                        )
                    }
                    onClick={onButtonClick}
                />
            </HoverTip>
        </Down>
    );
};

ResumeFoldersDropDown.propTypes = {
    trls: PropTypes.object,
    simHash: PropTypes.string,
    employerManagerId: PropTypes.number,
    resumeHash: PropTypes.string,
    hasResumeInFolders: PropTypes.bool,
    resumeId: PropTypes.string,
    vacancyId: PropTypes.string,
};

ResumeFoldersDropDown.trls = {
    tipTitle: 'topbuttons.resume.favorite',
};

export { ResumeFoldersDropDown as ResumeFoldersDropDownRaw };
export default connect((state) => ({
    hasResumeInFolders: !!state.resumeFolders?.foldersByResume?.[state.resume?.hash],
    resumeHash: state.resume?.hash,
    simHash: state.resume?.simHash,
    folders: state.resumeFolders.foldersInvariants,
    employerManagerId: parseInt(state.employerManager?.id, 10),
    selectedIdsFolder: state.resumeFolders?.foldersByResume?.[state.resume?.hash],
    resumeId: state.resume?.id,
    vacancyId: state.router.location.vacancyId,
}))(translation(ResumeFoldersDropDown));
