import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import History from 'src/pages/ResumeView/components/View/ResumeSidebar/History';

const TrlKeys = {
    title: 'resume.employer.history.title',
};

const HistorySidebar: TranslatedComponent = ({ trls }) => {
    const { mSidebar } = useGetMColumnSize();
    const negotiationsHistory = useSelector(({ negotiationsHistory }) => negotiationsHistory);
    const shouldBeRendered = useSelector(({ printVersion }) => printVersion.history !== false);

    if (!negotiationsHistory.length || !shouldBeRendered) {
        return null;
    }

    return (
        <Column xs="0" s="4" m={mSidebar} l="4">
            <div className="resume-sidebar-section" data-qa="resume-history-sidebar-container">
                <H3>{trls[TrlKeys.title]}</H3>
                <VSpacing base={4} />
                <History />
            </div>
        </Column>
    );
};

export default translation(HistorySidebar);
