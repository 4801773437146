import { useRef, RefObject, useEffect, useMemo } from 'react';
import { Primitive } from 'type-fest';

import Analytics from '@hh.ru/analytics-js';
import { Bound } from 'bloko/common/elementSpy';

import { useSelector } from 'lux/modules/useSelector';

interface AdditionalButton {
    buttonName: string;
    label?: string;
    action?: string;
    fromPlace?: string;
}

type GetInnerShownButtons = (ref: RefObject<HTMLElement>) => AdditionalButton[];

const getInnerShownButtons: GetInnerShownButtons = (ref) => {
    const additionalButtons: AdditionalButton[] = [];
    if (ref?.current) {
        const analyticsElements = [
            ...ref.current.querySelectorAll('.HH-Employer-Vacancies-Analytics'),
        ] as HTMLElement[];
        analyticsElements.forEach((element) => {
            const buttonName = element.dataset.analyticsButtonName;
            const label = element.dataset.analyticsLabel;
            const action = element.dataset.analyticsAction;
            const fromPlace = element.dataset.analyticsFromPlace;
            if (buttonName) {
                additionalButtons.push({ buttonName, label, action, fromPlace });
            }
        });
    }
    return additionalButtons;
};

type UseShownAnalytics = (
    params?: Record<string, Primitive>,
    condition?: boolean,
    withInnerButtons?: boolean
) => RefObject<HTMLElement>;
type UseShownRawAnalyticsWithInnerButtons = (
    ref: RefObject<HTMLElement>
) => (elementName: string, params?: Record<string, string>, condition?: boolean) => void;
type UseClickAnalytics = () => (buttonName: string, params?: Record<string, Primitive>, condition?: boolean) => void;

export const useShownAnalytics: UseShownAnalytics = (params, condition = true, withInnerButtons = false) => {
    const isAnalyticsEnabled = useSelector((state) => state.isClickmeAnalyticsEnabled);

    const blockRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (isAnalyticsEnabled && condition && blockRef?.current) {
            const innerButtons = withInnerButtons
                ? { additionalShownButtons: JSON.stringify(getInnerShownButtons(blockRef)) }
                : {};

            Analytics.sendHHEventElementShown(
                blockRef.current,
                { ...params, ...innerButtons },
                {},
                { trackingBounds: [Bound.Left, Bound.Top], elementLockTime: 500 }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition, isAnalyticsEnabled, ...(params ? Object.values(params) : [])]);

    return blockRef;
};

export const useShownRawAnalyticsWithInnerButtons: UseShownRawAnalyticsWithInnerButtons = (ref) => {
    const isAnalyticsEnabled = useSelector((state) => state.isClickmeAnalyticsEnabled);

    return useMemo(
        () =>
            (elementName, params, condition = true) => {
                if (isAnalyticsEnabled && condition) {
                    setTimeout(() => {
                        const buttons = getInnerShownButtons(ref);
                        if (buttons.length > 0) {
                            const innerButtons = { additionalShownButtons: JSON.stringify(buttons) };
                            Analytics.sendHHEvent('element_shown', { elementName, ...params, ...innerButtons });
                        }
                    }, 100);
                }
            },
        [isAnalyticsEnabled, ref]
    );
};

export const useClickAnalytics: UseClickAnalytics = () => {
    const isAnalyticsEnabled = useSelector((state) => state.isClickmeAnalyticsEnabled);

    return (buttonName, params, condition = true) => {
        if (isAnalyticsEnabled && condition) {
            // В params попадает массив vacanciesIds, который обрезается возможной длиной query параметров
            // Поэтому шлем в body запроса
            Analytics.sendHHEventButtonClick(buttonName, {}, params);
        }
    };
};
