import { ComponentProps } from 'react';

import Column from 'bloko/blocks/column';

import { useSelector } from 'lux/modules/useSelector';
import { selectorMColumnSize } from 'src/components/HiringManager/utils/selectorMColumnSize';

type MSizes = ComponentProps<typeof Column>['m'];

const useGetMColumnSize = (): { mMain: MSizes; mSidebar: MSizes } => {
    return useSelector(selectorMColumnSize);
};

export default useGetMColumnSize;
