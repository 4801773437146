export default {
    changeTopic: 'changeTopic',
    invite: 'invite',
    inviteAnother: 'inviteAnother',
    inviteWithAssessements: 'inviteWithAssessements',
    discard: 'discard',
    quickDiscard: 'quick_discard',
    complaint: 'complaint',
    consider: 'consider',
    mail: 'mail',
};
