import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormApi } from 'final-form';

import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { addCommentToUser } from 'lux/models/employer/resume/commentsByUser';
import { addComment, ResumeComment } from 'lux/models/employer/resume/resumeComments';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentAddMessage } from 'src/components/Notifications/ResumeComments';
import { HiringManagerCommentFormData } from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/types';

const TrlKeys = {
    candidateAccepted: 'resume.comments.hiringManager.candidateAccepted',
    candidateRejected: 'resume.comments.hiringManager.candidateRejected',
};

export const useSendCommentSubmit = (
    trls: LangTrls,
    setIsExpanded?: Dispatch<SetStateAction<boolean>>
): ((data: HiringManagerCommentFormData, form: FormApi<HiringManagerCommentFormData>) => Promise<void>) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const applicantId = useSelector((state) => state.resume?.user);

    const add = useCallback(
        (data: ResumeComment) => {
            if (!applicantId) {
                return;
            }
            dispatch([addComment(data), addCommentToUser({ userId: +applicantId, commentId: data.id })]);
        },
        [dispatch, applicantId]
    );

    return useCallback(
        async (data: HiringManagerCommentFormData, form: FormApi<HiringManagerCommentFormData>): Promise<void> => {
            if (!applicantId) {
                return;
            }
            let trlKey;
            if (data.verdict) {
                trlKey = data.verdict === 'accept' ? 'candidateAccepted' : 'candidateRejected';
            }

            let body = data.text;
            if (data.verdict) {
                const trlVerdict = trls[TrlKeys[trlKey as keyof typeof TrlKeys]];
                body = data.text ? `${trlVerdict}\n\n${data.text}` : trlVerdict;
            }

            try {
                const response = await fetcher.postFormData('/employer/applicant/json_comment', {
                    body,
                    applicantId: +applicantId,
                    action: 'add',
                    accessType: 'COWORKERS',
                });
                add(response.data);
                addNotification(resumeCommentAddMessage, { props: { message: response.data.body } });
                form.restart();
                setIsExpanded?.(false);
            } catch (e) {
                defaultRequestErrorHandler(e, addNotification);
            }
        },
        [add, addNotification, applicantId, setIsExpanded, trls]
    );
};
