import Gap from 'bloko/blocks/gap';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { useProfile } from 'lux/hooks/useProfile';

import ResponseLetter from 'src/pages/ResumeView/components/ResponseLetter';
import ResumeBody from 'src/pages/ResumeView/components/View/ResumeBody';

const ResumeMain = () => {
    const isProfile = useProfile();
    const breakpoint = useBreakpoint();
    return (
        <Gap top={!isProfile || [Breakpoint.XS, Breakpoint.S].includes(breakpoint)}>
            <ResponseLetter />
            <ResumeBody />
        </Gap>
    );
};

export default ResumeMain;
