import { FC } from 'react';

import { useProfile } from 'lux/hooks/useProfile';

const ResumeHeaderDivider: FC = () => {
    if (useProfile()) {
        return null;
    }
    return <div className="resume-header-divider" />;
};

export default ResumeHeaderDivider;
