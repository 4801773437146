import { useSelector } from 'react-redux';

import VSpacing from 'bloko/blocks/vSpacing';

import { EDIT } from 'lux/modules/resumePermission';

import JobSearchStatusName from 'src/pages/ResumeView/components/ResumeHeader/ResumeJobSearchStatusName';

const ResumeJobSearchStatus = () => {
    const userId = useSelector((state) => state.resume.user);
    const applicantUserStatus = useSelector((state) => state.resume.applicantUserStatuses?.[userId]);
    const isOwnResume = useSelector((state) => state.resume.permission) === EDIT;

    if (!applicantUserStatus?.jobSearchStatus?.name) {
        return null;
    }

    return (
        <div className="resume-job-search-status">
            <VSpacing base={4} />
            <JobSearchStatusName currentStatus={applicantUserStatus.jobSearchStatus.name} isOwnResume={isOwnResume} />
            <VSpacing base={4} />
        </div>
    );
};

export default ResumeJobSearchStatus;
