import PropTypes from 'prop-types';

import { UserType } from 'lux/models/userType';
import { OTHER_CONTACTS } from 'lux/modules/resumeHiddenFields';
import { EDIT } from 'lux/modules/resumePermission';
import { useSelector } from 'lux/modules/useSelector';
import PersonalSites from 'src/components/ResumeContacts/PersonalSites';
import PersonalSitesGroup from 'src/components/ResumeContacts/PersonalSitesGroup';
import ResumePhoneDeepLinks from 'src/components/ResumePhoneDeepLinks';
import translation from 'src/components/translation';

const ResumeContactsPersonalSites = ({ trls }) => {
    const shouldHideSites = useSelector(
        ({ resume: { permission, hiddenFields } }) => permission !== EDIT && hiddenFields.includes(OTHER_CONTACTS)
    );
    const { phone, id: resumeId, simHash, created, hash: resumeHash } = useSelector(({ resume }) => resume);
    const shouldShowDeepLinks = useSelector(
        ({ userType }) => userType === UserType.Employer && !!phone.value?.[0]?.raw
    );

    if (shouldHideSites) {
        return null;
    }
    return (
        <>
            <PersonalSites />
            {shouldShowDeepLinks && (
                <PersonalSitesGroup withDeepLink name={trls[ResumeContactsPersonalSites.trls.eventual]}>
                    <ResumePhoneDeepLinks
                        phone={phone.value[0]}
                        resumeId={resumeId}
                        simHash={simHash}
                        created={created}
                        resumeHash={resumeHash}
                        fromResumeView={true}
                    />
                </PersonalSitesGroup>
            )}
        </>
    );
};

ResumeContactsPersonalSites.propTypes = {
    trls: PropTypes.object,
};

ResumeContactsPersonalSites.trls = {
    eventual: 'rb.personalsites.eventual',
};

export default translation(ResumeContactsPersonalSites);
