import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ResumeCreds } from 'lux/models/resume/resumeCreds.types';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

import QuestionAnswer from 'src/pages/ResumeView/components/CredsView/QuestionAnswer';
import { isAnyQuestionRendered } from 'src/pages/ResumeView/components/CredsView/utils';

const TrlKeys = {
    emptyText: 'rb.creds.empty.text',
    fillButton: 'rb.creds.empty.button',
};

interface CredsViewProps {
    creds: ResumeCreds;
}

const CredsView: TranslatedComponent<CredsViewProps> = ({ creds, trls }) => {
    const resumeHash = useSelector(({ resume }) => resume?.hash);
    const anyQuestionRendered = isAnyQuestionRendered(creds);

    if (!anyQuestionRendered) {
        return (
            <div>
                <Text size={TextSize.Large} importance={TextImportance.Secondary}>
                    {trls[TrlKeys.emptyText]}
                </Text>
                <VSpacing base={4} />
                <Button
                    Element={Link}
                    to={`/applicant/resumes/edit/creds?resume=${resumeHash || ''}`}
                    appearance={ButtonAppearance.Outlined}
                    kind={ButtonKind.Primary}
                >
                    {trls[TrlKeys.fillButton]}
                </Button>
            </div>
        );
    }

    return (
        <>
            {Object.entries(creds.questionToAnswerMap).map(([questionId, answers]) => (
                <QuestionAnswer key={questionId} questionId={questionId} answers={answers} />
            ))}
        </>
    );
};

export default translation(CredsView);
