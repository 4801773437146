// tempexp_HH-23996_file
import { FC } from 'react';

import { Card, Link, Text, VSpacing } from '@hh.ru/magritte-ui';

import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { HrlinkBannerProps } from 'src/components/HrlinkBanner/types';

import styles from './index.less';

const HrlinkBannerAdv: FC<HrlinkBannerProps> = ({ onButtonClick, url }) => {
    return (
        <Card showBorder borderRadius={16} padding={16}>
            <div className={styles.logoTitle} />
            <VSpacing default={16} />
            <Text typography="label-3-regular" style="secondary">
                Подписывайте кадровые документы онлайн{NON_BREAKING_SPACE}за{NON_BREAKING_SPACE}1 минуту
            </Text>
            <VSpacing default={8} />
            <Link
                href={url}
                typography="label-3-regular"
                target="_blank"
                rel="nofollow noopener"
                onClick={onButtonClick}
            >
                Подключить сервис
            </Link>
        </Card>
    );
};

export default HrlinkBannerAdv;
