import classNames from 'classnames';
import PropTypes from 'prop-types';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import { H2, H1 } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { useProfile } from 'lux/hooks/useProfile';
import FormatMoney from 'src/components/FormatMoney';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

const ResumePositionTitle = ({ title, salary, trls }) => {
    const { mMain } = useGetMColumnSize();
    const isProfile = useProfile();
    const isXS = useBreakpoint() === Breakpoint.XS;
    const salaryContent = salary ? (
        <span
            className={classNames('resume-block__salary', {
                'resume-block-column__salary': isProfile,
            })}
            data-qa="resume-block-salary"
        >
            <FormatMoney currency={salary.currency}>{salary.amount}</FormatMoney> {trls[ResumePositionTitle.trls.net]}
        </span>
    ) : null;
    const content = (
        <>
            <div className={classNames('resume-block-position', { 'resume-block-column-position': isProfile })}>
                <div className="resume-block__title-text-wrapper">
                    {isProfile && (
                        <H1>
                            <span className="resume-block__title-text" data-qa="resume-block-title-position">
                                <AsyncHighlighter>{title}</AsyncHighlighter>
                            </span>
                        </H1>
                    )}
                    {!isProfile && (
                        <H2>
                            <span className="resume-block__title-text" data-qa="resume-block-title-position">
                                <AsyncHighlighter>{title}</AsyncHighlighter>
                            </span>
                        </H2>
                    )}

                    {!isProfile && <ResumeEditLink block="position" data-qa="resume-block-position-edit" />}
                </div>
                {salary && (
                    <div className={classNames({ 'resume-block-position-salary': !isProfile })}>
                        <div className="resume-block-container">
                            <VSpacing base={0} xs={4} />
                            {!isProfile && <H2>{salaryContent}</H2>}
                            {isProfile && (
                                <>
                                    {!isXS && <VSpacing base={3} />}
                                    <Text size={TextSize.Large}>{salaryContent}</Text>
                                    {!isXS && <VSpacing base={1} />}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <VSpacing base={4} />
        </>
    );
    if (isProfile) {
        return content;
    }
    return (
        <ColumnsRow>
            <Column xs="4" s="8" m={mMain} l="12">
                {content}
            </Column>
        </ColumnsRow>
    );
};

ResumePositionTitle.propTypes = {
    trls: PropTypes.object,
    title: PropTypes.node,
    salary: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
    }),
};

ResumePositionTitle.trls = {
    net: 'resume.salary.net',
};

export default translation(ResumePositionTitle);
