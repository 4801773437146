import { useEffect, useState, useRef, DependencyList } from 'react';
import axios, { AxiosError } from 'axios';

import debounce from 'bloko/common/debounce';
import RateLimitedAction from 'bloko/common/helpers/rateLimitedAction';

import fetcher from 'lux/modules/fetcher';

interface UseGetResponse<U extends keyof FetcherGetApi> {
    loading: boolean;
    data: null | FetcherGetApi[U]['response'];
    error: null | AxiosError | unknown;
}

interface UseFetchOption<U extends keyof FetcherGetApi> {
    deps?: DependencyList;
    debounceDelay?: number;
    onSuccess?: (data: FetcherGetApi[U]['response']) => void;
    onError?: (error: UseGetResponse<U>['error']) => void;
    enabled?: boolean;
}

type DebouncedFunction = ReturnType<RateLimitedAction>;

const useGet = <U extends keyof FetcherGetApi>(
    url: U,
    params?: FetcherGetApi[U]['queryParams'],
    options?: UseFetchOption<U>
): UseGetResponse<U> => {
    const { debounceDelay, onSuccess, onError, deps = [], enabled = true } = options || {};

    const [loading, setLoading] = useState<UseGetResponse<U>['loading']>(false);
    const [error, setError] = useState<UseGetResponse<U>['error']>(null);
    const [data, setData] = useState<UseGetResponse<U>['data']>(null);

    const debouncedRequestRef = useRef<DebouncedFunction>();

    useEffect(() => {
        if (!enabled) {
            return () => null;
        }

        setLoading(true);
        const abortController = new AbortController();

        async function fetchData(params: FetcherGetApi[U]['queryParams'], abortController: AbortController) {
            try {
                const response = await fetcher.get(url, { signal: abortController.signal, params });

                setData(response);
                setError(null);
                onSuccess?.(response);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setData(null);
                    setError(error);
                    onError?.(error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (debounceDelay && !debouncedRequestRef.current) {
            debouncedRequestRef.current = debounce(
                (params: FetcherGetApi[U]['queryParams'], abortController: AbortController) => {
                    void fetchData(params, abortController);
                },
                debounceDelay
            );
        }

        if (debouncedRequestRef.current) {
            debouncedRequestRef.current(params, abortController);
        } else {
            void fetchData(params, abortController);
        }

        return () => {
            abortController.abort();
            debouncedRequestRef.current?.cancel();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceDelay, enabled, ...deps]);

    return { loading, data, error };
};

export default useGet;
