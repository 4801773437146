import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import BlokoLink from 'bloko/blocks/link';

import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

import PersonalSitesGroup from 'src/components/ResumeContacts/PersonalSitesGroup';
import ResumeContactsSocialSite, {
    sendAnalyticsOnSocialLinkClick,
} from 'src/components/ResumeContacts/ResumeContactsSocialSite';

const PERSONAL_TYPE = 'personal';

const PersonalSites = ({ trls }) => {
    const phone = useSelector((state) => state.resume?.phone);
    const personalSites = useSelector((state) => state.resume?.personalSite.value);

    const [personalSite, socialSites] = useMemo(() => {
        const personalSite = personalSites.find(({ type, url }) => type === PERSONAL_TYPE && !!url);
        const sites = personalSites.filter(({ type }) => type !== PERSONAL_TYPE);
        const withUrl = sites.filter(({ url }) => !!url);
        const withoutUrl = sites.filter(({ url }) => !url);

        return [personalSite, [...withUrl, ...withoutUrl]];
    }, [personalSites]);

    return (
        <>
            {personalSite && (
                <div data-qa={`resume-personalsite-${personalSite.type}`}>
                    <BlokoLink
                        href={personalSite.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={sendAnalyticsOnSocialLinkClick}
                    >
                        {personalSite.text}
                    </BlokoLink>
                </div>
            )}
            {socialSites.length > 0 && (
                <PersonalSitesGroup withDeepLink={!!phone.value?.[0]?.raw} name={trls[PersonalSites.trls.submitted]}>
                    {socialSites.map((site) => (
                        <ResumeContactsSocialSite key={site.type} site={site} />
                    ))}
                </PersonalSitesGroup>
            )}
        </>
    );
};

PersonalSites.propTypes = {
    trls: PropTypes.object,
};

PersonalSites.trls = {
    submitted: 'rb.personalsites.submitted',
};

export default memo(translation(PersonalSites));
