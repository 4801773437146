import { memo } from 'react';
import PropTypes from 'prop-types';

import resumeEmailButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_email_button_click';
import Text, { TextImportance } from 'bloko/blocks/text';

import { EMAIL } from 'lux/modules/resumeHiddenFields';
import { EDIT } from 'lux/modules/resumePermission';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import { EMAIL as PREFERRED_CONTACT_EMAIL } from 'src/components/ResumeContacts/preferredContacts';
import translation from 'src/components/translation';

const ResumeContactsEmail = ({ trls }) => {
    const canEdit = useSelector(({ resume }) => resume.permission === EDIT);
    const email = useSelector(({ resume }) => resume.email.value);
    const isHidden = useSelector(({ resume }) => resume.hiddenFields.includes(EMAIL));
    const isPreferred = useSelector(({ resume }) => resume.preferredContact.value === PREFERRED_CONTACT_EMAIL);
    const resumeId = useSelector(({ resume }) => resume.id);
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);

    if (isHidden && !canEdit) {
        return (
            <div className="resume-hidden-field-wrapper">
                <div className="resume-hidden-field">
                    <Text Element="span" importance={TextImportance.Tertiary}>
                        {trls[ResumeContactsEmail.trls.isHidden]}
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <div data-qa="resume-contact-email">
            <a
                href={`mailto:${email}`}
                data-qa={isPreferred ? 'resume-contact-preferred' : null}
                onClick={() => resumeEmailButtonClick({ vacancyId, resumeId })}
            >
                <AsyncHighlighter>{email}</AsyncHighlighter>
            </a>
            {isPreferred && `${NON_BREAKING_SPACE}— ${trls[ResumeContactsEmail.trls.isPreferred]}`}
        </div>
    );
};

ResumeContactsEmail.trls = {
    isHidden: 'resume.hiddenFields.email',
    isPreferred: 'rb.view.preferredcontact',
};

ResumeContactsEmail.propTypes = {
    trls: PropTypes.object.isRequired,
};

export default translation(memo(ResumeContactsEmail));
