import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ButtonGroup from 'bloko/blocks/button/ButtonGroup';
import FormSpacer from 'bloko/blocks/form/FormSpacer';

import { UserType } from 'lux/models/userType';
import { EDIT } from 'lux/modules/resumePermission';
import ResumeButtonDelete from 'src/components/ResumeActions/ButtonDelete';
import ResumeButtonPrint from 'src/components/ResumeActions/ButtonPrint';

import ResumeButtonDownload from 'src/pages/ResumeView/components/ResumeHeader/ResumeButtonDownload';
import ResumeButtonSendMessage from 'src/pages/ResumeView/components/ResumeHeader/ResumeButtonSendMessage';
import ResumeChangeLanguage from 'src/pages/ResumeView/components/ResumeHeader/ResumeChangeLanguage';
import ResumeFoldersDropDown from 'src/pages/ResumeView/components/ResumeHeader/ResumeFoldersDropDown';
import ResumeTalantixButton from 'src/pages/ResumeView/components/ResumeHeader/ResumeTalantixButton';

const ResumeButtons = ({ hash, canEdit, isHhru, userType }) => {
    if (userType === UserType.Anonymous) {
        return null;
    }

    return (
        <div className="resume-header-actions">
            {canEdit && <ResumeChangeLanguage />}
            <div className="resume-header-actions__buttons-wrapper">
                <FormSpacer>
                    <ButtonGroup stretched>
                        {userType === UserType.Employer && <ResumeFoldersDropDown />}
                        {isHhru && userType === UserType.Employer && <ResumeTalantixButton />}
                        <ResumeButtonDownload />
                        <ResumeButtonPrint />
                        {canEdit && <ResumeButtonDelete hash={hash} />}
                        <ResumeButtonSendMessage />
                    </ButtonGroup>
                </FormSpacer>
            </div>
        </div>
    );
};

ResumeButtons.propTypes = {
    hash: PropTypes.string,
    canEdit: PropTypes.bool,
    isHhru: PropTypes.bool,
    userType: PropTypes.string,
};

export default connect((state) => ({
    hash: state.resume?.hash,
    canEdit: state.resume?.permission === EDIT,
    isHhru: state.topLevelSite === 'hh.ru',
    userType: state.userType,
}))(ResumeButtons);
