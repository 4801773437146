import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'lux/modules/useSelector';
import paths from 'src/app/routePaths';
import translation from 'src/components/translation';

const TrlKeys = {
    similarTitle: 'employer.nomoreviews.similarResumeContactsService.apply',
    apply: 'Apply',
};

const BuyContactsResumeHasSim: TranslatedComponent = ({ trls }) => {
    const simVacancyId = useSelector(({ snippetAccessResume }) => snippetAccessResume?.sim?.vacancyId);

    if (simVacancyId) {
        return (
            <>
                <H3>{trls[TrlKeys.similarTitle]}</H3>
                <VSpacing base={4} />
                <Button
                    kind={ButtonKind.Primary}
                    data-qa="show-contacts-resume-apply"
                    Element={Link}
                    to={`${paths.resumeSearch}/similar/apply?vacancy_id=${simVacancyId}`}
                >
                    {trls[TrlKeys.apply]}
                </Button>
            </>
        );
    }

    return null;
};

export default translation(BuyContactsResumeHasSim);
