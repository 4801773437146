import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { VSpacing } from '@hh.ru/magritte-ui';

import { useProfile } from 'lux/hooks/useProfile';
import { MALE } from 'lux/modules/resumeGenders';
import ConversionAge from 'src/components/Conversion/Age';
import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';

const TrlKeys = {
    metro: 'rb.edit.metro.m',
    'gender.male': 'resumeConverter.gender.male',
    'gender.female': 'resumeConverter.gender.female',
    'gender.unknown': 'resumeConverter.gender.unknown',

    'born.male': 'resumeConverter.born.male',
    'born.female': 'resumeConverter.born.female',
    'born.unknown': 'resumeConverter.born.unknown',
};

const ResumePersonalGender = translation(({ trls }) => {
    const gender = useSelector((state) => state.resume?.gender.value || MALE);
    const age = useSelector((state) => state.resume?.age.value);
    const birthday = useSelector((state) => state.resume?.birthday.value);
    const isEmpty = useSelector((state) => state.resume?.emptyBlocks.includes('personal'));

    if (useProfile()) {
        return null;
    }

    const renderGender = () => {
        if (!gender) {
            return null;
        }
        return <span data-qa="resume-personal-gender">{trls[TrlKeys[`gender.${gender}`]]}</span>;
    };

    const renderAge = () => {
        if (!birthday) {
            return null;
        }
        const birthdayTimezoneLeveling = `${birthday}T00:00:00`;
        return (
            <Fragment>
                {', '}
                <span data-qa="resume-personal-age">
                    <ConversionAge value={age} />
                </span>
                {', '}
                {trls[TrlKeys[`born.${gender}`]]}
                &nbsp;
                <span data-qa="resume-personal-birthday">
                    <HumanDate date={birthdayTimezoneLeveling} />
                </span>
            </Fragment>
        );
    };

    if (isEmpty) {
        return (
            <div className="resume-header-block">
                <ResumeEmptyBlock block="personal" />
            </div>
        );
    }

    return (
        <>
            <p>
                {renderGender()}
                {renderAge()}
            </p>
            <ResumeEditLink block="personal" data-qa="resume-block-personal-edit" WrapperComponent="p" />
            <VSpacing default={4} />
        </>
    );
});

export default ResumePersonalGender;
