import { useEffect, useMemo, useRef } from 'react';

import resumeKeySkillVerificationElementShown from '@hh.ru/analytics-js-events/build/xhh/common/verification_key_skill/resume_key_skill_verification_element_shown';
import { resumeKeySkillVerificationHintElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/common/verification_key_skill/resume_key_skill_verification_hint_element_shown';
import { HoverTip } from 'bloko/blocks/drop';
import { TipPlacement } from 'bloko/blocks/drop/Tip';
import Tag from 'bloko/blocks/tagList/tag';
import { TagKind } from 'bloko/blocks/tagList/types';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { MonthIndex } from 'lux/models/monthIndex';
import { useSelector } from 'lux/modules/useSelector';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import OnboardingSkillsToolTip from 'src/components/OnbordingSkillsToolTip/OnboardingSkillsToolTip';
import useVisibleOnboardingSkillsToolTip from 'src/components/OnbordingSkillsToolTip/useVisibleOnboardingSkillsToolTip';
import translation from 'src/components/translation';

interface ResumeLanguage {
    title: string;
    id: number;
    degree: string;
}

interface LanguagesTagInnerProps {
    language: ResumeLanguage;
    isVerified: boolean;
    trls: LangTrls;
}

const TrlKeys = {
    tipConfirmedByTest: 'resume.skills.verification.tip.confirmedByTest',
    tipConfirmedAt: 'resume.skills.verification.tip.confirmedAt',
    title: 'rb.title.languages',
    levels: {
        basic: 'resumeBuilder.languages.level.basic',
        fluent: 'resumeBuilder.languages.level.fluent',
        [`can_pass_interview`]: 'resumeBuilder.languages.level.can_pass_interview',
        native: 'resumeBuilder.languages.level.native',
        [`can_read`]: 'resumeBuilder.languages.level.can_read',
        a1: 'resumeBuilder.languages.level.a1',
        a2: 'resumeBuilder.languages.level.a2',
        b1: 'resumeBuilder.languages.level.b1',
        b2: 'resumeBuilder.languages.level.b2',
        c1: 'resumeBuilder.languages.level.c1',
        c2: 'resumeBuilder.languages.level.c2',
        l1: 'resumeBuilder.languages.level.l1',
    },
    shortLevels: {
        a1: 'resumeBuilder.languages.level.a1.short',
        a2: 'resumeBuilder.languages.level.a2.short',
        b1: 'resumeBuilder.languages.level.b1.short',
        b2: 'resumeBuilder.languages.level.b2.short',
        c1: 'resumeBuilder.languages.level.c1.short',
        c2: 'resumeBuilder.languages.level.c2.short',
        l1: 'resumeBuilder.languages.level.l1.short',
    },
    assessmentMade: 'resumeBuilder.assessment.test.made',
    months: {
        0: 'calendar.prepositional.month.0',
        1: 'calendar.prepositional.month.1',
        2: 'calendar.prepositional.month.2',
        3: 'calendar.prepositional.month.3',
        4: 'calendar.prepositional.month.4',
        5: 'calendar.prepositional.month.5',
        6: 'calendar.prepositional.month.6',
        7: 'calendar.prepositional.month.7',
        8: 'calendar.prepositional.month.8',
        9: 'calendar.prepositional.month.9',
        10: 'calendar.prepositional.month.10',
        11: 'calendar.prepositional.month.11',
    },
};

const LanguageTagInner: TranslatedComponent<LanguagesTagInnerProps> = ({ language, isVerified, trls }) => {
    const verifiedSkills = useSelector((state) => state.resume?.verifiedSkills) ?? [];
    const resumeId = useSelector((state) => state.resume?.id ?? '0');

    const { isTipVisible, setIsTipVisible, skillVerificationTip } = useVisibleOnboardingSkillsToolTip({
        skillName: language.title,
        skillCategory: 'LANG',
    });

    const needSendHintAnalyticRef = useRef(true);
    const verifiedTagRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (verifiedTagRef.current && resumeId && isVerified) {
            resumeKeySkillVerificationElementShown(verifiedTagRef.current, {
                keySkill: language.title,
                resumeId: Number(resumeId),
                practiceVerified: false,
            });
        }
    }, [isVerified, language.title, resumeId]);

    const hoverTipHandler = (isMouseOver: boolean | null) => {
        if (needSendHintAnalyticRef.current && isMouseOver && resumeId) {
            resumeKeySkillVerificationHintElementShownRaw({
                keySkill: language.title,
                resumeId: Number(resumeId),
                isOnboarding: false,
                practiceVerified: false,
            });
            needSendHintAnalyticRef.current = false;
        }
    };

    const verifiedSkill = verifiedSkills.find((skill) => skill.id === language.id);

    const degree = trls[TrlKeys.levels[language.degree as keyof typeof TrlKeys.levels]];
    const degreeShort = trls[TrlKeys.shortLevels[language.degree as keyof typeof TrlKeys.shortLevels]];

    const EmptyTagInnerContent = useMemo(
        () => (
            <p data-qa="resume-block-language-item">
                {language.title}
                {degree && (
                    <>
                        {' — '}
                        {degree}
                    </>
                )}
            </p>
        ),
        [degree, language.title]
    );

    if (!isVerified) {
        return (
            <>
                <Tag>{EmptyTagInnerContent}</Tag>
                <br />
            </>
        );
    }

    if (!verifiedSkill) {
        return <AsyncHighlighter>{language.title}</AsyncHighlighter>;
    }

    const lastSkillVerification = verifiedSkill.verifications[verifiedSkill.verifications.length - 1];

    if (!lastSkillVerification) {
        return <AsyncHighlighter>{language.title}</AsyncHighlighter>;
    }

    const attemptedMonthIndex = new Date(lastSkillVerification.attemptedAt).getMonth() as MonthIndex;
    const attemptedYear = new Date(lastSkillVerification.attemptedAt).getFullYear();
    const attemptedMonth = trls[TrlKeys.months[attemptedMonthIndex]];

    const attemptedAtText = format(trls[TrlKeys.tipConfirmedAt], {
        '{0}': `${attemptedMonth} ${attemptedYear}`,
    });

    const assessmentSource =
        lastSkillVerification.method.brandingSettings?.enabled &&
        lastSkillVerification.method.source.name &&
        trls[TrlKeys.assessmentMade]
            ? format(trls[TrlKeys.assessmentMade], {
                  '{0}': `${lastSkillVerification.method.source.name}.`,
              })
            : '';

    const renderContent = () => {
        if (isTipVisible) {
            return (
                <OnboardingSkillsToolTip
                    attemptedAtText={attemptedAtText}
                    methodName={lastSkillVerification.method.name}
                    setIsTipVisible={setIsTipVisible}
                    skillVerificationTip={skillVerificationTip}
                    keySkill={language.title}
                    resumeId={resumeId}
                >
                    {EmptyTagInnerContent}
                </OnboardingSkillsToolTip>
            );
        }
        return (
            <HoverTip
                placement={TipPlacement.Bottom}
                host={!process.env.LUX_SERVER ? document.body : null}
                onHover={hoverTipHandler}
                flexible={true}
                render={() => (
                    <>
                        <p>{trls[TrlKeys.tipConfirmedByTest]}</p>
                        {'«'}
                        {language.title}
                        {' — '}
                        {degreeShort}
                        {'».'}
                        <p>{`${attemptedAtText}.`}</p>
                        {assessmentSource && (
                            <>
                                <br />
                                <p className="resume-language-level-drop-source">{assessmentSource}</p>
                            </>
                        )}
                    </>
                )}
            >
                {EmptyTagInnerContent}
            </HoverTip>
        );
    };

    return (
        <div ref={verifiedTagRef}>
            <Tag kind={TagKind.Success}>{renderContent()}</Tag>
            <br />
        </div>
    );
};
export default translation(LanguageTagInner);
