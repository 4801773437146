import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { ChatikContext } from '@hh.ru/chatik-integration';
import Column, { ColumnsRow, ColumnsWrapper } from 'bloko/blocks/column';
import { H2 } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';
import { format } from 'bloko/common/trl';
import urlParser from 'bloko/common/urlParser';

import { UserType } from 'lux/models/userType';
import { VIEW_WITHOUT_CONTACTS } from 'lux/modules/resumePermission';
import { ResumeTags } from 'lux/modules/resumeStatuses';
import { addTabListener, removeTabListener } from 'lux/utils/viewDuration';
import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import GetPageHtml from 'src/components/GetPageHtml/GetPageHtml.obfuscate';
import { selectorMColumnSize } from 'src/components/HiringManager/utils/selectorMColumnSize';
import { AsyncHighlighterContext, getAsyncHighlighter } from 'src/components/Markup/AsyncHighlighterModule';
import defaultError from 'src/components/Notifications/DefaultError';
import { NotificationsHOC } from 'src/components/Notifications/Provider';
import {
    contactInformationViewInfoMessage,
    resumeViewModerationCorrectedMessage,
} from 'src/components/Notifications/ResumeView';
import AdditionalCheckResumeInfo from 'src/components/ResumeAdditionalCheck/AdditionalCheckResumeInfo';
import mapErrorKeyToNotification from 'src/components/ResumeView/mapErrorKeyToNotification';
import SuitableGiftsPromo from 'src/components/SuitableGiftsPromo';
import translation from 'src/components/translation';

import BackToVacancyResponses from 'src/pages/ResumeView/components/BackToVacancyResponses';
import ProfileBanner from 'src/pages/ResumeView/components/ProfileBanner';
import RelatedResumes from 'src/pages/ResumeView/components/RelatedResumes';
import ResumeAnonymousAccess from 'src/pages/ResumeView/components/ResumeAnonymous/Access';
import ResumeErrorWrapper from 'src/pages/ResumeView/components/ResumeErrorWrapper';
import ResumeHeader from 'src/pages/ResumeView/components/ResumeHeader';
import ResumeHeaderDivider from 'src/pages/ResumeView/components/ResumeHeaderDivider';
import ResumeMain from 'src/pages/ResumeView/components/ResumeMain';
import ResumeWithoutContactInfo from 'src/pages/ResumeView/components/ResumeWithoutContactInfo';
import PrintSettings from 'src/pages/ResumeView/components/View/PrintSettings';
import ResumeModerationControls from 'src/pages/ResumeView/components/View/ResumeModerationControls';
import ResumeNudge from 'src/pages/ResumeView/components/View/ResumeNudge';
import ResumeSidebar from 'src/pages/ResumeView/components/View/ResumeSidebar';
import ResumeSidebarSmall from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeSidebarSmall';
import ResumeTabs from 'src/pages/ResumeView/components/View/ResumeTabs';

class ResumeView extends PureComponent {
    getTitle = () => {
        const { resume, trls, userType } = this.props;
        let title;
        if (resume.error) {
            return trls[ResumeView.trls.notexists];
        }
        if (resume.title?.value) {
            if (UserType.Employer === userType) {
                title = resume.title.value;
            } else {
                title = format(trls[ResumeView.trls.pageTitle], { '{0}': resume.title.value });
            }
        } else {
            title = trls[ResumeView.trls.newResume];
        }
        return title;
    };

    renderAnonymousAccess = () => {
        const { userType, resume } = this.props;
        if (userType !== UserType.Anonymous || resume.permission !== VIEW_WITHOUT_CONTACTS) {
            return null;
        }
        return <ResumeAnonymousAccess />;
    };

    // блокируем показ и редактирование резюме для владельца резюме пока эксперт маркетплейса работает над ним
    isBlockedViewAndEdit = () => {
        const { userId, resume, showResumeBlockedWhileExpertIsWorking } = this.props;
        const expertIsWorking = resume.tags?.includes(ResumeTags.ExpertIsWorking);
        const isOwner = resume.user === userId;
        return showResumeBlockedWhileExpertIsWorking && expertIsWorking && isOwner;
    };

    renderWIPResume = () => {
        const { resume, trls } = this.props;
        return (
            <ColumnsRow key={resume.id}>
                <Column xs="4" s="8" m="12" l="16">
                    <H2>{this.getTitle()}</H2>
                    <VSpacing base={4} />
                    <Text importance={TextImportance.Secondary}>{trls[ResumeView.trls.isPreparingByExpert]}</Text>
                </Column>
            </ColumnsRow>
        );
    };

    renderResume = () => {
        const { resume, searchQuery, lang, mColumnSize } = this.props;
        const { mMain } = mColumnSize;

        return (
            <Fragment key={resume.id}>
                {resume.nudge && <ResumeNudge />}
                <BackToVacancyResponses />
                <AsyncHighlighterContext.Provider value={getAsyncHighlighter(searchQuery)}>
                    <TranslationLangContext.Provider value={resume.lang}>
                        <ResumeModerationControls />
                        <GetPageHtml />
                        <ColumnsRow>
                            <Column xs="4" s="8" m="12" l="16" container>
                                <ColumnsRow>
                                    <ResumeHeader />
                                </ColumnsRow>

                                <ResumeHeaderDivider />
                            </Column>
                        </ColumnsRow>
                        <ResumeSidebarSmall />
                        <div className="resume-wrapper">
                            <ColumnsRow>
                                <Column xs="4" s="8" m={mMain} l="12" container>
                                    <ProfileBanner desktopOnly bottomSpacing={8} />
                                    <TranslationLangContext.Provider value={lang}>
                                        <ResumeTabs topicId={this.props.location.query.t} />
                                    </TranslationLangContext.Provider>
                                    <ResumeMain />
                                    <RelatedResumes />
                                </Column>
                            </ColumnsRow>
                            <ResumeSidebar topicId={this.props.location.query.t} />
                        </div>
                    </TranslationLangContext.Provider>
                </AsyncHighlighterContext.Provider>
            </Fragment>
        );
    };

    showNotificationsFromParams() {
        const { empty, message, error } = urlParser(this.props.search).params;

        if (message && message.length && message[0] === 'moderation_corrected') {
            this.props.addNotification(resumeViewModerationCorrectedMessage);
        }

        if (empty) {
            this.props.addNotification(contactInformationViewInfoMessage);
        }

        if (error) {
            this.props.addNotification(mapErrorKeyToNotification[error[0]] || defaultError);
        }
    }

    chatHandler() {
        const { chatId, t: topicId } = urlParser(this.props.search).params;
        if (chatId && !topicId) {
            this.context.openChatik({ chatId, view: 'widget', resizable: false, draggable: false });
        }
    }

    /**
     * Для гроу хака показа резюме в чате скрываем лого и настройки версии для печати и показываем ее
     * TODO выпелить после проведения экпа в PORTFOLIO-25399
     */
    tryToHideLogo() {
        const shouldHideLogo = this.props.location.query.hideLogo;
        const isPrintVersion = this.props.location.query.print;

        if (!isPrintVersion || !shouldHideLogo) {
            return;
        }

        const logo = document.querySelector('.print-logo-wrapper');
        logo && logo.classList.add('g-hidden');
    }

    componentDidMount() {
        if (this.props.resume?.hash) {
            LocalStorageWrapper.setItem('LastOpenedResume', this.props.resume.hash);
        }
        this.showNotificationsFromParams();
        this.chatHandler();
        this.tryToHideLogo();
        addTabListener(this.props.viewDuration);
    }

    componentDidUpdate(prevProps) {
        if (this.props.viewDuration !== prevProps.viewDuration) {
            // recalc event
            removeTabListener();
            addTabListener(this.props.viewDuration);
        }
    }

    componentWillUnmount() {
        const { error } = urlParser(this.props.search).params;

        this.props.removeNotification(contactInformationViewInfoMessage);
        this.props.removeNotification(resumeViewModerationCorrectedMessage);

        if (error) {
            this.props.removeNotification(mapErrorKeyToNotification[error[0]] || defaultError);
        }
        removeTabListener();
    }

    render() {
        const shouldShowSettings = !this.props.location.query.hideSettings;

        return (
            <PageLayout errorWrapper={ResumeErrorWrapper} layout={EmptyLayout} title={this.getTitle()}>
                <ColumnsWrapper>
                    {!this.isBlockedViewAndEdit() && (
                        <ColumnsRow>
                            <Column xs="4" s="8" m="12" l="16">
                                {this.renderAnonymousAccess()}
                                <SuitableGiftsPromo />
                                <ResumeWithoutContactInfo />
                                <AdditionalCheckResumeInfo />
                            </Column>
                        </ColumnsRow>
                    )}
                    <ColumnsRow>
                        <Column xs="4" s="8" m="12" l="16" container>
                            <div className="resume-applicant">
                                {!this.isBlockedViewAndEdit() ? (
                                    <>
                                        {shouldShowSettings && <PrintSettings />}
                                        {this.renderResume()}
                                    </>
                                ) : (
                                    this.renderWIPResume()
                                )}
                            </div>
                        </Column>
                    </ColumnsRow>
                </ColumnsWrapper>
            </PageLayout>
        );
    }
}

ResumeView.propTypes = {
    infoName: PropTypes.string,
    userType: PropTypes.string,
    userId: PropTypes.string,
    resume: PropTypes.object,
    searchQuery: PropTypes.string,
    search: PropTypes.string,
    location: PropTypes.shape({
        query: PropTypes.shape({
            t: PropTypes.string,
            hideLogo: PropTypes.string,
            hideSettings: PropTypes.string,
            print: PropTypes.string,
        }),
    }),
    push: PropTypes.func,
    addNotification: PropTypes.func,
    removeNotification: PropTypes.func,
    trls: PropTypes.object,
    lang: PropTypes.string.isRequired,
    showResumeBlockedWhileExpertIsWorking: PropTypes.bool,
    viewDuration: PropTypes.shape({
        eventType: PropTypes.string,
        params: PropTypes.shape({
            [`resume_id`]: PropTypes.string,
            [`from_request_id`]: PropTypes.string,
            [`view_request_id`]: PropTypes.string,
        }),
    }),
    mColumnSize: PropTypes.object.isRequired,
};

ResumeView.trls = {
    notexists: 'resume.employer.notexists',
    newResume: 'rb.view.newresume',
    pageTitle: 'resume.page.title',
    isPreparingByExpert: 'resumeList.resume.status.isPreparingByExpert',
};

ResumeView.features = {
    showResumeBlockedWhileExpertIsWorking: 'show_resume_blocked_while_expert_is_working',
};

ResumeView.contextType = ChatikContext;

export default connect(
    (state) => ({
        userType: state.userType,
        userId: state.userId,
        resume: state.resume,
        searchQuery: state.searchQuery || '',
        search: state.router.location.search,
        viewDuration: state.viewDuration,
        lang: state.langs[0],
        infoName: state.infoTip.name,
        showResumeBlockedWhileExpertIsWorking:
            state.features[ResumeView.features.showResumeBlockedWhileExpertIsWorking],
        mColumnSize: selectorMColumnSize(state),
    }),
    { push }
)(translation(NotificationsHOC(ResumeView)));
