import Column from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { Permission } from 'lux/models/resume/resumeCommon.types';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';
import CredsView from 'src/pages/ResumeView/components/CredsView';
import { isAnyQuestionRendered } from 'src/pages/ResumeView/components/CredsView/utils';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

const TrlKeys = {
    title: 'rb.title.creds',
};

const ResumeCreds: TranslatedComponent = ({ trls }) => {
    const { mMain } = useGetMColumnSize();
    const creds = useSelector(({ resume }) => resume?.creds);
    const canEdit = useSelector((state) => state.resume?.permission === Permission.Edit);

    if (!creds) {
        return null;
    }

    const anyQuestionRendered = isAnyQuestionRendered(creds);

    if (!anyQuestionRendered && !canEdit) {
        return null;
    }

    return (
        <ResumeBlock
            title={trls[TrlKeys.title]}
            EditLink={anyQuestionRendered && <ResumeEditLink block="creds" data-qa="resume-block-creds-edit" />}
            data-qa="resume-block-creds"
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    <CredsView creds={creds} />
                </div>
            </Column>
        </ResumeBlock>
    );
};

export default translation(ResumeCreds);
