import { Fragment, useCallback } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import { useProfile } from 'lux/hooks/useProfile';
import { JobSearchStatus } from 'lux/models/applicantUserStatuses';
import { CareerBannerPosition, CareerLinksKeys } from 'lux/models/careerPlatformState';
import { useSelector } from 'lux/modules/useSelector';
import CareerPlatformBanner from 'src/components/CareerPlatformBanner/index';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeAdditional from 'src/pages/ResumeView/components/View/ResumeAdditional';
import ResumeCertificates from 'src/pages/ResumeView/components/View/ResumeCertificates';
import ResumeCompetencies from 'src/pages/ResumeView/components/View/ResumeCompetencies';
import ResumeCreds from 'src/pages/ResumeView/components/View/ResumeCreds';
import ResumeDriverExperience from 'src/pages/ResumeView/components/View/ResumeDriverExperience';
import ResumeEducation from 'src/pages/ResumeView/components/View/ResumeEducation';
import ResumeEducationAdditional from 'src/pages/ResumeView/components/View/ResumeEducationAdditional';
import ResumeEducationAttestation from 'src/pages/ResumeView/components/View/ResumeEducationAttestation';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';
import ResumeExperience from 'src/pages/ResumeView/components/View/ResumeExperience';
import ResumeKeySkills from 'src/pages/ResumeView/components/View/ResumeKeySkills';
import ResumeLanguages from 'src/pages/ResumeView/components/View/ResumeLanguages';
import ResumePortfolio from 'src/pages/ResumeView/components/View/ResumePortfolio';
import ResumePosition from 'src/pages/ResumeView/components/View/ResumePosition';
import ResumeRecommendation from 'src/pages/ResumeView/components/View/ResumeRecommendation';
import ResumeSkillSurvey from 'src/pages/ResumeView/components/View/ResumeSkillSurvey';
import ResumeSkills from 'src/pages/ResumeView/components/View/ResumeSkills';

const ResumeBody = () => {
    const isProfile = useProfile();
    const linksState = useSelector((state) => state.careerPlatformLinks);
    const userId = useSelector((state) => state.resume.user);
    const applicantUserStatus = useSelector((state) => state.resume.applicantUserStatuses?.[userId]);
    const showCareerBanner = Boolean(
        linksState &&
            linksState.links &&
            [JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers].includes(
                applicantUserStatus?.jobSearchStatus?.name
            )
    );
    const isEmptyExperience = useSelector((state) => state.resume?.emptyBlocks.includes('experience'));
    const isEmptyEducation = useSelector((state) => state.resume?.emptyBlocks.includes('education'));
    const { mMain } = useGetMColumnSize();
    const renderExperience = useCallback(() => {
        if (isEmptyExperience) {
            return (
                <Fragment>
                    <Column xs="4" s="8" m={mMain} l="12">
                        <ResumeEmptyBlock block="experience" />
                    </Column>
                    <ResumeCreds />
                    <ResumeCompetencies />
                    <ResumeDriverExperience />
                    {showCareerBanner && (
                        <Column xs="4" s="8" m={mMain} l="12">
                            <CareerPlatformBanner
                                position={CareerBannerPosition.Resume}
                                destination={CareerLinksKeys.Courses}
                            />
                            <VSpacing base={6} />
                        </Column>
                    )}
                </Fragment>
            );
        }

        return (
            <Fragment>
                <ResumeExperience />
                <ResumeCreds />
                <ResumeKeySkills />
                <ResumeCompetencies />
                <ResumeDriverExperience />
                <ResumeSkills />
                <ResumeRecommendation />
                <ResumePortfolio />
            </Fragment>
        );
    }, [isEmptyExperience, mMain, showCareerBanner]);

    const renderEducation = useCallback(() => {
        if (isEmptyEducation) {
            return (
                <Column xs="4" s="8" m={mMain} l="12">
                    <ResumeEmptyBlock block="education" />
                </Column>
            );
        }
        return (
            <Fragment>
                <ResumeEducation />
                <ResumeLanguages />
                <ResumeEducationAdditional />
                <ResumeEducationAttestation />
                <ResumeCertificates />
            </Fragment>
        );
    }, [isEmptyEducation, mMain]);

    return (
        <Fragment>
            {!isProfile && <ResumePosition />}

            <ResumeSkillSurvey />
            {renderExperience()}
            {renderEducation()}
            <ResumeAdditional />
        </Fragment>
    );
};

export default ResumeBody;
