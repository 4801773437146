import PropTypes from 'prop-types';

import { useSelector } from 'lux/modules/useSelector';
import { DefaultLayout } from 'src/app/layouts/PageLayout';
import PageErrorWrapper from 'src/components/PageErrorWrapper';

import ResumeError from 'src/pages/ResumeView/components/View/ResumeError';

const ResumeErrorWrapper = ({ children }) => {
    const resume = useSelector(({ resume }) => resume);

    if (resume.error) {
        const viewLimit = resume.viewLimit ? { viewLimit: resume.viewLimit } : {};
        return (
            <DefaultLayout>
                <ResumeError {...viewLimit} />
            </DefaultLayout>
        );
    }
    return <PageErrorWrapper>{children}</PageErrorWrapper>;
};

ResumeErrorWrapper.propTypes = {
    children: PropTypes.node,
};

export default ResumeErrorWrapper;
