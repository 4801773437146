import { useLayoutEffect, useRef, useState } from 'react';

import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { GroupedMessages } from 'lux/models/employer/resume/pinnedChatMessages';
import translation from 'src/components/translation';

import styles from './resume-chat-pinned-messages.less';

interface ChatMessageGroupProps {
    messagesGroup: GroupedMessages;
    isEmployerMessages: boolean;
}

const TrlKeys = {
    linkSwitch: 'resume.pinnedChatMessage.linkSwitch',
};

const isEllipsisActive = (element: HTMLElement) => element.offsetHeight < element.scrollHeight;

const ChatMessageGroup: TranslatedComponent<ChatMessageGroupProps> = ({ trls, messagesGroup, isEmployerMessages }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [linkSwitchVisible, setLinkSwitchVisible] = useState(false);
    const [clipped, setClipped] = useState(true);

    useLayoutEffect(() => {
        if (ref.current) {
            setLinkSwitchVisible(isEllipsisActive(ref.current));
        }
    }, []);
    return (
        <>
            <div ref={ref} className={clipped ? styles.clippedMessage : ''}>
                {messagesGroup.messages.map((message) => (
                    <Text
                        key={message.id}
                        size={isEmployerMessages ? TextSize.Small : TextSize.Medium}
                        importance={isEmployerMessages ? TextImportance.Tertiary : TextImportance.Primary}
                    >
                        {message.text}
                    </Text>
                ))}
            </div>
            {linkSwitchVisible && (
                <Link
                    appearance={LinkAppearance.Pseudo}
                    onClick={() => {
                        setClipped(false);
                        setLinkSwitchVisible(false);
                    }}
                    kind={LinkKind.Tertiary}
                >
                    {trls[TrlKeys.linkSwitch]}
                </Link>
            )}
        </>
    );
};

export default translation(ChatMessageGroup);
