import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import { format } from 'bloko/common/trl';

import { EDIT } from 'lux/modules/resumePermission';
import { useSelector } from 'lux/modules/useSelector';
import SkillsSurvey from 'src/components/SkillsSurvey';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';

const ResumeSkillSurvey = ({ trls }) => {
    const { mMain } = useGetMColumnSize();
    const canEdit = useSelector(({ resume }) => resume.permission === EDIT);
    const surveyRef = useRef();
    const resumeId = useSelector((state) => state.resume?.id);
    const hasSkillsSurvey = useSelector((state) => state.resume?.hasSkillsSurvey);
    const { surveyProfession, surveyProfessionTrl } = useSelector((state) => state.resume) || {};

    useEffect(() => {
        if (surveyRef.current) {
            Analytics.sendHHEventElementShown(surveyRef.current, {
                name: 'resume_skill_survey',
                type: 'skills_survey',
                resumeId,
                surveyProfession,
            });
        }
    }, [resumeId, surveyProfession]);

    if (!hasSkillsSurvey || !surveyProfession) {
        return null;
    }

    const sendEditAnalytics = () => {
        Analytics.sendHHEventButtonClick('resume_edit_survey', {
            type: 'skills_survey',
            resumeId,
            surveyProfession,
        });
    };

    return (
        <ResumeBlock
            title={format(trls[ResumeSkillSurvey.trls.title], { '{0}': surveyProfessionTrl })}
            EditLink={
                canEdit ? (
                    <Link
                        className={'resume-block-edit'}
                        data-qa={'resume-block-recommendation-edit'}
                        to={`/skills_survey/applicant?surveyProfession=${surveyProfession}&source=${resumeId}`}
                        onClick={sendEditAnalytics}
                    >
                        <Text Element="span" size={TextSize.Small}>
                            {trls[ResumeSkillSurvey.trls.edit]}
                        </Text>
                    </Link>
                ) : null
            }
            data-qa={'resume-block-skill-survey'}
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    <SkillsSurvey blockRef={surveyRef} />
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumeSkillSurvey.propTypes = {
    trls: PropTypes.object,
};

ResumeSkillSurvey.trls = {
    title: 'rb.title.skillsSurvey.base',
    edit: 'rb.edit',
};

export default translation(ResumeSkillSurvey);
