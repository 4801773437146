import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import Text, { TextImportance } from 'bloko/blocks/text';

import formatDate from 'Modules/formatDate';
import Result from 'src/components/Employer/AssessmentResult';
import translation from 'src/components/translation';

function Shl({ trls, assessmentsData, resumeHash, employerResumesNegotiationLinks, visible }) {
    if (!visible || !assessmentsData || !assessmentsData.assessments) {
        return null;
    }

    const inviteWithAssessments =
        employerResumesNegotiationLinks[resumeHash] &&
        employerResumesNegotiationLinks[resumeHash].find((link) => link.transitionType === 'inviteWithAssessements');

    let inviteWithAssessmentsJsx = '';
    if (inviteWithAssessments) {
        inviteWithAssessmentsJsx = (
            <a
                href={inviteWithAssessments.location}
                onClick={() => VendorAnalytics.eventToStorage('employer', 'invite', 'recommended')}
                data-qa="resume__invite-with-assessment-button"
            >
                {trls[Shl.trls.inviteWithAssessments]}
            </a>
        );
    }

    return (
        <Fragment>
            {inviteWithAssessmentsJsx}
            {assessmentsData.assessments.map((assessment) => (
                <div className="resume-sidebar-item" key={assessment.assessmentId}>
                    <div className="resume-sidebar-item__text-wrapper">{trls[Shl.trls[assessment.code]]}</div>
                    <div className="resume-sidebar-item__info">
                        <Text importance={TextImportance.Tertiary}>
                            <span suppressHydrationWarning>{formatDate(assessment.creationTime, 'dd.MM.yy')}</span>
                        </Text>
                    </div>
                    <div className="resume-sidebar-item__info">
                        <Text importance={TextImportance.Tertiary}>
                            <Result assessment={assessment} />
                        </Text>
                    </div>
                </div>
            ))}
        </Fragment>
    );
}

Shl.defaultProps = {
    visible: true,
};

Shl.propTypes = {
    visible: PropTypes.bool,
    trls: PropTypes.object,
    assessmentsData: PropTypes.shape({
        count: PropTypes.number,
        assessments: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                state: PropTypes.string,
                creationTime: PropTypes.string,
                assessmentId: PropTypes.number,
            })
        ),
    }),
    resumeHash: PropTypes.string,
    employerResumesNegotiationLinks: PropTypes.objectOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                location: PropTypes.string,
                type: PropTypes.string,
            })
        )
    ),
};

Shl.trls = {
    inviteWithAssessments: 'resume.button.inviteToAssessment',

    SHL_NUMERIC: 'assessments.tools.SHL_NUMERIC',
    SHL_OPQ: 'assessments.tools.SHL_OPQ',
    SHL_VERBAL: 'assessments.tools.SHL_VERBAL',
    SKILLAZ_VIDEO_INTERVIEW: 'assessments.tools.SKILLAZ_VIDEO_INTERVIEW',
};

export default connect((state) => ({
    assessmentsData: state.assessments,
    employerResumesNegotiationLinks: state.employerResumesNegotiationLinks,
    resumeHash: state.resume?.hash,
}))(translation(Shl));
