import { useRef, useState, useEffect, useLayoutEffect, ReactNode, useCallback, useMemo } from 'react';

import { Button as MagritteButton, ActionList, ActionListProps } from '@hh.ru/magritte-ui';
import { EllipsisHorizontalOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button from 'bloko/blocks/button';
import Menu, { MenuLayer, MenuPlacement } from 'bloko/blocks/drop/Menu';
import { EllipsisScaleSmallKindVertical } from 'bloko/blocks/icon';
import VSpacingContainer from 'bloko/blocks/vSpacing/VSpacingContainer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useShownRawAnalyticsWithInnerButtons } from 'lux/hooks/useMyVacanciesAnalytics';
import useToggleState from 'lux/hooks/useToggleState';
import translation from 'src/components/translation';

interface AdaptiveButtonsProps {
    buttons: ReactNode[];
    menuItems: ({ onClose }: { onClose: () => void }) => ReactNode[];
    withEmployerVacanciesAnalytics?: boolean;
    redesign?: boolean;
}

const TrlKeys = {
    showMore: 'adaptiveButtons.showMore',
};

const AdaptiveButtons: TranslatedComponent<AdaptiveButtonsProps> = ({
    trls,
    buttons,
    menuItems,
    withEmployerVacanciesAnalytics = false,
    redesign = false,
}) => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isCalculationInProgress, setIsCalculationInProgress] = useState(true);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    const buttonsFiltered = useMemo(() => (isFirstRender ? [] : buttons.filter(Boolean)), [isFirstRender, buttons]);

    const containerRef = useRef<HTMLDivElement>(null);
    const buttonsRef = useRef<HTMLSpanElement[]>([]);
    const menuButtonRef = useRef<HTMLSpanElement>(null);
    const menuButtonsRef = useRef<HTMLDivElement>(null);
    const actionListActivatorRef = useRef<HTMLButtonElement>(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [buttonsWidths, setButtonsWidths] = useState<number[]>([]);
    const [buttonsShown, setButtonsShown] = useState(buttonsFiltered.length);
    const [menuIsOpen, toggleMenuIsOpen, setMenuIsOpen] = useToggleState(false);
    const closeDrop = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen]);

    const shownButtonsRawAnalytics = useShownRawAnalyticsWithInnerButtons(containerRef);
    const shownMenuRawAnalytics = useShownRawAnalyticsWithInnerButtons(menuButtonsRef);

    useEffect(() => {
        if (withEmployerVacanciesAnalytics) {
            shownButtonsRawAnalytics('employer_vacancies_vacancy_button_bar');
        }
    }, [shownButtonsRawAnalytics, withEmployerVacanciesAnalytics]);

    const startCalculationForWindowResize = useCallback(() => {
        setIsCalculationInProgress(true);
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', startCalculationForWindowResize);
        setIsCalculationInProgress(true);
        return () => window.removeEventListener('resize', startCalculationForWindowResize);
    }, [startCalculationForWindowResize]);

    useLayoutEffect(() => {
        setIsCalculationInProgress(true);
    }, [buttonsFiltered]);

    useLayoutEffect(() => {
        if (isCalculationInProgress) {
            setContainerWidth(containerRef.current?.getBoundingClientRect().width || 0);
            setButtonsWidths(buttonsRef.current?.map((element) => element.getBoundingClientRect().width) || 0);
        }
    }, [isCalculationInProgress]);

    useLayoutEffect(() => {
        const menuButtonLeftOffset = 4;
        const menuButtonWidth = (menuButtonRef.current?.getBoundingClientRect().width || 0) + menuButtonLeftOffset;

        let sumWidth = 0;
        let newButtonsShowed = 0;

        for (const width of buttonsWidths) {
            const requiredWidthWithOneMoreButton = sumWidth + width + menuButtonWidth;

            if (requiredWidthWithOneMoreButton > containerWidth) {
                break;
            }
            newButtonsShowed += 1;
            sumWidth += width;
        }
        setButtonsShown(newButtonsShowed);
        setIsCalculationInProgress(false);
    }, [buttonsWidths, containerWidth]);

    const toggleMenu = () => {
        if (withEmployerVacanciesAnalytics) {
            shownMenuRawAnalytics('employer_vacancies_vacancy_button_bar_menu');
        }
        toggleMenuIsOpen();
    };

    const dropProps = useMemo<ActionListProps['dropProps']>(
        () => ({
            activatorRef: actionListActivatorRef,
            placement: 'bottom-left',
            role: 'status',
        }),
        []
    );

    const menuElements = menuItems({ onClose: closeDrop }).slice(buttonsShown);
    return (
        <div ref={containerRef} className="adaptive-buttons-container">
            {isCalculationInProgress && (
                <div className="adaptive-buttons-width-computer">
                    {buttonsFiltered.map((button, index) => (
                        <span key={index} ref={(ref) => ref && (buttonsRef.current[index] = ref)}>
                            {button}
                        </span>
                    ))}
                </div>
            )}
            {buttonsFiltered.slice(0, buttonsShown).map((button, index) => (
                <span key={index}>{button}</span>
            ))}
            <span className="adaptive-buttons-spacer" />
            <span
                className={buttonsShown === buttonsFiltered.length ? 'adaptive-buttons-menu-button_transparent' : ''}
                ref={menuButtonRef}
            >
                {redesign ? (
                    <>
                        <MagritteButton
                            mode="secondary"
                            aria-label={trls[TrlKeys.showMore]}
                            onClick={toggleMenu}
                            hideLabel
                            icon={<EllipsisHorizontalOutlinedSize24 initial="primary" />}
                            ref={actionListActivatorRef}
                            data-qa="adaptive-buttons-menu-button"
                        >
                            {trls[TrlKeys.showMore]}
                        </MagritteButton>
                        <ActionList visible={menuIsOpen} onClose={closeDrop} dropProps={dropProps}>
                            <VSpacingContainer base={2}>{menuElements.map((element) => element)}</VSpacingContainer>
                        </ActionList>
                    </>
                ) : (
                    <Menu
                        show={menuIsOpen}
                        layer={MenuLayer.Overlay}
                        onClose={closeDrop}
                        placement={MenuPlacement.BottomEnd}
                        render={() => <div ref={menuButtonsRef}>{menuElements}</div>}
                    >
                        <Button
                            onClick={toggleMenu}
                            icon={<EllipsisScaleSmallKindVertical />}
                            data-qa="adaptive-buttons-menu-button"
                        />
                    </Menu>
                )}
            </span>
        </div>
    );
};

export default translation(AdaptiveButtons);
