import { Dispatch } from 'react';
import { DefaultRootState } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import { Action, NonNullableRootState } from '@hh.ru/redux-create-reducer';

import { BillingPlatform, DBAccessProductType } from 'lux/models/price/product.types';
import { Resume } from 'lux/models/resume/resume.types';
import { getApiCount, getContactsCountForPlatform } from 'lux/utils/price/products';

interface AnalyticParams {
    apiCount: number;
    civCount: number;
    priceRegionId: string;
    period: string;
    resumeId: Resume['id'];
    employerId: string;
    price: DBAccessProductType['price'];
    tariff?: string;
}
const getAnalyticsParams =
    (product: DBAccessProductType) =>
    (
        dispatch: Dispatch<ThunkAction<Action | null, DefaultRootState, unknown, Action> | Action>,
        getState: () => NonNullableRootState
    ): AnalyticParams => {
        const { period, region, price, tariff } = product;
        const apiCount = getApiCount(product);
        const contactsCountHh = getContactsCountForPlatform(product, BillingPlatform.HH);
        const resume = getState().resume;
        const employerId = getState().employerId;
        const resumeId = resume.id;

        return {
            apiCount: Number(apiCount),
            civCount: Number(contactsCountHh),
            priceRegionId: region,
            period,
            resumeId,
            employerId,
            price,
            tariff,
        };
    };

export default getAnalyticsParams;
