import { FC } from 'react';
import classnames from 'classnames';

import Tag from 'bloko/blocks/tagList/tag';
import { TagKind } from 'bloko/blocks/tagList/types';

import { KeySkill } from 'lux/models/resume/resumeCommon.types';
import {
    VerifiedBy,
    VerifiedSkill,
    VerifiedSkillCategory,
    VSkillValidityStatus,
} from 'lux/models/resume/resumeVerifiedSkills.types';
import { useSelector } from 'lux/modules/useSelector';
import KeySkillsTagInner from 'src/components/KeySkills/KeySkillsTagInner';

interface SkillTagProps {
    skill: KeySkill;
}

export const getAppropriateSkill = (skill: VerifiedSkill, id: number | string): boolean => {
    return skill.id === id && skill.category === VerifiedSkillCategory.Skill;
};

const getVerificationIcon = (verifiedBy: VerifiedBy, isPrintVersion: boolean) => {
    if (!isPrintVersion && (verifiedBy === VerifiedBy.TheoryAndPractice || verifiedBy === VerifiedBy.Practice)) {
        return (
            <span
                data-qa={`practice`}
                className={classnames(
                    'skills-verification-methods-rank-icon',
                    'skills-verification-methods-practice-theory-icon'
                )}
            />
        );
    }

    return null;
};
const SkillTag: FC<SkillTagProps> = ({ skill }: SkillTagProps): JSX.Element => {
    const resume = useSelector((state) => state.resume);
    const isPrintVersion = useSelector((state) => state.printVersion.isPrintVersion) || false;
    const resumeApplicantSkills = resume?.resumeApplicantSkills;
    const currentSkill = resumeApplicantSkills?.find((vSkill) => getAppropriateSkill(vSkill, skill.id));
    const rank = currentSkill?.level?.rank;
    const isVerified = [VSkillValidityStatus.Effective, VSkillValidityStatus.Grace].includes(
        (currentSkill?.theory || currentSkill?.practice)?.validity.state
    );

    const renderVerificationIcon = getVerificationIcon(currentSkill?.verifiedBy || VerifiedBy.None, isPrintVersion);

    return (
        <Tag kind={isVerified ? TagKind.Success : undefined} icon={renderVerificationIcon}>
            <KeySkillsTagInner skillName={skill.name} rank={rank} />
        </Tag>
    );
};

export default SkillTag;
