import { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H3 } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { format } from 'bloko/common/trl';

import { resumeAccessType, NO_ONE, DIRECT } from 'lux/models/resume/resumeAccess.types';
import { BLOCKED, NOT_FINISHED, CORRECTED, resumeStatusType } from 'lux/modules/resumeStatuses';
import translation from 'src/components/translation';

const ResumeVisibility = ({ hash, status, accessType, hiddenFields, resumeViewBackoffice, autoHideTime, trls }) => {
    const renderVisibilityPrefix = useCallback(() => {
        if (status === BLOCKED || status === CORRECTED) {
            return trls[ResumeVisibility.trls.prefixAfterUnblock];
        }
        if (status === NOT_FINISHED) {
            return trls[ResumeVisibility.trls.prefixFuture];
        }
        return trls[ResumeVisibility.trls.prefix];
    }, [status, trls]);

    const renderVisibilityHistoryLink = useCallback(() => {
        if (!resumeViewBackoffice.visibilityHistoryLink) {
            return null;
        }
        return (
            <div className="resume-sidebar-link">
                <Link to={resumeViewBackoffice.visibilityHistoryLink}>{trls[ResumeVisibility.trls.history]}</Link>
            </div>
        );
    }, [resumeViewBackoffice, trls]);

    const renderAutoHideTime = useCallback(() => {
        if (
            !autoHideTime ||
            [BLOCKED, CORRECTED, NOT_FINISHED].includes(status) ||
            [NO_ONE, DIRECT].includes(accessType)
        ) {
            return null;
        }

        return (
            <>
                <VSpacing base={2} />
                <Text importance={TextImportance.Secondary}>
                    {format(trls[ResumeVisibility.trls.autoHideTime.prefix], {
                        '{0}': trls[ResumeVisibility.trls.autoHideTime[autoHideTime]],
                    })}
                </Text>
            </>
        );
    }, [accessType, autoHideTime, status, trls]);

    const hiddenFieldsCount = hiddenFields.length;

    return (
        <Fragment>
            <H3>{trls[ResumeVisibility.trls.header]}</H3>
            <VSpacing base={4} />
            {renderVisibilityPrefix()}
            {trls[ResumeVisibility.trls[`accessType.${accessType}`]]}
            {renderAutoHideTime()}
            {hiddenFieldsCount > 0 && (
                <p className="resume-sidebar-link">
                    {trls[ResumeVisibility.trls.hasHiddenPrefix]}
                    &nbsp;
                    {hiddenFields.map((field, index) => {
                        const lastIndex = hiddenFieldsCount - 1;
                        return (
                            <Fragment key={field}>
                                {index !== lastIndex && index !== 0 && ', '}
                                {index === lastIndex && index !== 0 && ` ${trls[ResumeVisibility.trls.and]} `}
                                {trls[ResumeVisibility.trls[`hiddenField.${field}`]]}
                            </Fragment>
                        );
                    })}
                </p>
            )}
            <div className="resume-sidebar-link">
                <Link to={`/applicant/resumes/edit/visibility?resume=${hash}`} data-qa="resume-aside__visibility-link">
                    {trls[ResumeVisibility.trls.change]}
                </Link>
            </div>
            {renderVisibilityHistoryLink()}
        </Fragment>
    );
};

ResumeVisibility.propTypes = {
    hash: PropTypes.string.isRequired,
    status: resumeStatusType.isRequired,
    accessType: resumeAccessType.isRequired,
    hiddenFields: PropTypes.array.isRequired,
    resumeViewBackoffice: PropTypes.object.isRequired,
    autoHideTime: PropTypes.string,
    trls: PropTypes.object.isRequired,
};

ResumeVisibility.trls = {
    header: 'resume.sidebar.visibility',
    change: 'rb.visibility.change',
    prefix: 'rb.visibility.status.prefix',
    prefixFuture: 'rb.visibility.status.prefix.future',
    prefixAfterUnblock: 'rb.visibility.status.prefix.afterUnblock',
    'accessType.everyone': 'rb.visibility.status.everyone',
    'accessType.clients': 'rb.visibility.status.clients',
    'accessType.whitelist': 'rb.visibility.status.whitelist',
    'accessType.blacklist': 'rb.visibility.status.blacklist',
    'accessType.direct': 'rb.visibility.status.direct',
    'accessType.no_one': 'rb.visibility.status.no_one',
    hasHiddenPrefix: 'anonymousResume.hasHidden.pre',
    and: 'abstract.and',
    'hiddenField.names_and_photo': 'anonymousResume.hasHidden.field.names_and_photo',
    'hiddenField.phones': 'anonymousResume.hasHidden.field.phones',
    'hiddenField.email': 'anonymousResume.hasHidden.field.email',
    'hiddenField.other_contacts': 'anonymousResume.hasHidden.field.other_contacts',
    'hiddenField.experience': 'anonymousResume.hasHidden.field.experience',
    history: 'resume.sidebar.visibilityHistory',
    autoHideTime: {
        prefix: 'rb.visibility.autoHideTime.prefix',
        /* eslint-disable camelcase */
        year_5: 'rb.visibility.autoHideTime.suffix.year_5',
        year_3: 'rb.visibility.autoHideTime.suffix.year_3',
        year_2: 'rb.visibility.autoHideTime.suffix.year_2',
        month_12: 'rb.visibility.autoHideTime.suffix.month_12',
        month_10: 'rb.visibility.autoHideTime.suffix.month_10',
        month_8: 'rb.visibility.autoHideTime.suffix.month_8',
        /* eslint-enable camelcase */
    },
};

export default connect((state) => ({
    hash: state.resume?.hash,
    status: state.resume?.status,
    accessType: state.resume?.accessType,
    hiddenFields: state.resume?.hiddenFields,
    autoHideTime: state.resume?.autoHideTime,
    resumeViewBackoffice: state.resumeViewBackoffice || {},
}))(translation(ResumeVisibility));
