import { useState } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { HSpacing } from '@hh.ru/magritte-ui';
import Button from 'bloko/blocks/button';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'lux/modules/useSelector';
import InviteVideoCall from 'src/components/InviteToVideoCall';
import translation from 'src/components/translation';

const ResumeVideocallInviteButton = ({ applicant, isMenuItem, trls }) => {
    const [showInvite, setShowInvite] = useState(false);
    const searchUrl = useSelector((state) => state.router.location.search);
    const topicId = urlParser(searchUrl).params.t;

    if (isMenuItem) {
        return null;
    }

    const openModal = () => {
        setShowInvite(true);
        Analytics.sendHHEventButtonClick('video_call_resume_open', { topicId });
    };

    return (
        <>
            <Button data-qa="resume-video-call-invite-button" onClick={openModal}>
                {trls[ResumeVideocallInviteButton.trls.invite]}
            </Button>
            <HSpacing default={12} />
            {showInvite && <InviteVideoCall onClose={() => setShowInvite(false)} applicant={applicant} />}
        </>
    );
};

ResumeVideocallInviteButton.trls = {
    invite: 'resume.online.interview.invite',
};

ResumeVideocallInviteButton.propTypes = {
    applicant: PropTypes.object,
    isMenuItem: PropTypes.bool,
    trls: PropTypes.object,
};

export default translation(ResumeVideocallInviteButton);
