import { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import {
    SquaresScaleSmallIntersectFalse,
    LinkedinScaleSmallAppearanceDefault,
    FacebookScaleSmallAppearanceDefault,
    IcqScaleSmallAppearanceDefault,
    SkypeScaleSmallAppearanceDefault,
    FlScaleSmallAppearanceDefault,
    LivejournalScaleSmall,
    MScaleSmall,
    IconDynamic,
    IconColor,
} from 'bloko/blocks/icon';
import BlokoLink from 'bloko/blocks/link';

import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import translation from 'src/components/translation';

export const sendAnalyticsOnSocialLinkClick = () => {
    Analytics.sendEvent('employer', 'resume-with-social-link-click');
};

const PERSONAL_SITE_ICON = {
    personal: SquaresScaleSmallIntersectFalse,
    linkedin: LinkedinScaleSmallAppearanceDefault,
    facebook: FacebookScaleSmallAppearanceDefault,
    icq: IcqScaleSmallAppearanceDefault,
    skype: SkypeScaleSmallAppearanceDefault,
    freelance: FlScaleSmallAppearanceDefault,
    livejournal: LivejournalScaleSmall,
    moi_krug: MScaleSmall, // eslint-disable-line camelcase
};

const ResumeContactsSocialSite = ({ site: { type, text, url, isPreferred }, trls }) => {
    const contentText = text || trls[ResumeContactsSocialSite.trls[type]];
    const IconView = PERSONAL_SITE_ICON[type];

    if (url) {
        return (
            <span className="resume-header-contact">
                <BlokoLink
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={sendAnalyticsOnSocialLinkClick}
                >
                    <IconDynamic>
                        <span className="resume__contacts-links">
                            <span
                                className={classnames(`resume-contact-${type}`, {
                                    [`resume-contact-${type}_preferred`]: isPreferred,
                                })}
                                data-qa={`resume-personalsite-${type}`}
                            >
                                <IconView initial={IconColor.Gray80} highlighted={IconColor.Gray60} />
                                {NON_BREAKING_SPACE}
                                {contentText}
                            </span>
                        </span>
                    </IconDynamic>
                </BlokoLink>
                {isPreferred && (
                    <span className="resume-contacts-preferred-hint">
                        &nbsp;— {trls[ResumeContactsSocialSite.trls.isPreferred]}
                    </span>
                )}
            </span>
        );
    }

    return (
        <span className="resume-header-contact" data-qa={`resume-personalsite-${type}`}>
            <IconView initial={IconColor.Gray80} highlighted={IconColor.Gray60} />
            {NON_BREAKING_SPACE}
            {contentText}
        </span>
    );
};

ResumeContactsSocialSite.trls = {
    icq: 'rb.personalsites.icq',
    skype: 'rb.personalsites.skype',
    facebook: 'rb.personalsites.facebook',
    moi_krug: 'rb.personalsites.moi_krug', // eslint-disable-line camelcase
    freelance: 'rb.personalsites.freelance',
    livejournal: 'rb.personalsites.livejournal',
    linkedin: 'rb.personalsites.linkedin',
    personal: 'rb.personalsites.personal',
    isPreferred: 'resume.view.preferredcontact',
};

ResumeContactsSocialSite.propTypes = {
    site: PropTypes.object,
    trls: PropTypes.object,
};

export default memo(translation(ResumeContactsSocialSite));
