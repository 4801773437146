import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import { useProfile } from 'lux/hooks/useProfile';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

const ResumeDriverExperience = ({ hasVehicle, driverLicenseTypes, trls }) => {
    const { mMain } = useGetMColumnSize();
    if (useProfile()) {
        return null;
    }
    if (!driverLicenseTypes.length) {
        return null;
    }
    return (
        <ResumeBlock
            title={trls[ResumeDriverExperience.trls.title]}
            data-qa="resume-block-driver-experience"
            EditLink={
                <ResumeEditLink
                    block="experience"
                    field="driverLicenseTypes"
                    data-qa="resume-block-driver-experience-edit"
                />
            }
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    {hasVehicle && <p>{trls[ResumeDriverExperience.trls.hasVehicle]}</p>}
                    {driverLicenseTypes && (
                        <Fragment>
                            {trls[ResumeDriverExperience.trls.driverLicenseTypes]}
                            &nbsp;
                            {driverLicenseTypes
                                .map((type) => trls[ResumeDriverExperience.trls[`driverLicenseTypes.${type}`]])
                                .join(', ')}
                        </Fragment>
                    )}
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumeDriverExperience.propTypes = {
    hasVehicle: PropTypes.bool,
    driverLicenseTypes: PropTypes.array.isRequired,
    trls: PropTypes.object,
};

ResumeDriverExperience.trls = {
    title: 'rb.title.driverExperience',
    hasVehicle: 'resume.block.hasVehicle',
    driverLicenseTypes: 'resume.block.driverLicenseTypes',
    'driverLicenseTypes.A': 'driverLicenseTypes.A',
    'driverLicenseTypes.B': 'driverLicenseTypes.B',
    'driverLicenseTypes.C': 'driverLicenseTypes.C',
    'driverLicenseTypes.D': 'driverLicenseTypes.D',
    'driverLicenseTypes.E': 'driverLicenseTypes.E',
    'driverLicenseTypes.BE': 'driverLicenseTypes.BE',
    'driverLicenseTypes.CE': 'driverLicenseTypes.CE',
    'driverLicenseTypes.DE': 'driverLicenseTypes.DE',
    'driverLicenseTypes.TM': 'driverLicenseTypes.TM',
    'driverLicenseTypes.TB': 'driverLicenseTypes.TB',
};

export default connect((state) => ({
    hasVehicle: state.resume?.hasVehicle.value,
    driverLicenseTypes: state.resume?.driverLicenseTypes.value,
}))(translation(ResumeDriverExperience));
