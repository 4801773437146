import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingColor, LoadingScale } from 'bloko/blocks/loading';
import urlParser from 'bloko/common/urlParser';

import openContacts from 'Modules/Resume/Contacts/openContacts.obfuscate';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';
import { useNotification } from 'src/components/Notifications/Provider';
import mapErrorKeyToNotification from 'src/components/ResumeView/mapErrorKeyToNotification';
import translation from 'src/components/translation';

import DivisionSelector from 'src/pages/ResumeView/components/ResumeHeader/Contacts/DivisionSelector';

const defaultErrorNotification = mapErrorKeyToNotification.resourceUnavailable;

const OpenContacts = ({ trls }) => {
    const { hash: resumeHash, id: resumeId, phonePreview } = useSelector(({ resume }) => resume);
    const visible = useSelector(({ resume }) => resume.resumeContactsOpening?.canOpenContactsThroughCIV);
    const currentDivisionId = useSelector((state) => state.employerCurrentDivision?.divisionId);
    const [divisionId, setDivisionId] = useState(currentDivisionId);
    const vacancyId = useSelector(({ router }) => router.location.query?.vacancyId);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { addNotification } = useNotification();
    const hasVerifiedPhone = phonePreview?.some((phone) => phone.verified);
    const openAndMarkContacts = useCallback(
        (event) => {
            setButtonDisabled(true);
            openContacts({ divisionId, resumeHash, resumeId, event, vacancyId })
                .then(({ data }) => {
                    if (data?.errorKey) {
                        addNotification(mapErrorKeyToNotification[data.errorKey] || defaultErrorNotification);
                        setButtonDisabled(false);
                        return;
                    }

                    Analytics.sendHHEvent('resumeShowContacts', {
                        goal: 'OpenContacts',
                        resumeId,
                        vacancyId,
                    });

                    const url = urlParser(window.location);
                    delete url.params.error;
                    // Списался последний контакт, на перезагруженной странице будет уведомление
                    if (data?.contactsLeft === 0) {
                        url.params.empty = true;
                    }
                    // Просто чтобы отличать в логах страницы со свежеоткрытыми контактами
                    if (data?.contactsOpened) {
                        url.params.contactsOpened = data.contactsOpened;
                    }

                    window.location.assign(url.href);
                })
                .catch(() => {
                    setButtonDisabled(false);
                    addNotification(defaultErrorNotification);
                });
        },
        [addNotification, divisionId, resumeHash, resumeId, vacancyId]
    );

    if (!visible) {
        return null;
    }

    return (
        <div className="resume__contacts">
            <div>
                {hasVerifiedPhone && (
                    <div className="resume-contacts-verified-phone">
                        <CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse initial={IconColor.Green60} />
                        {NON_BREAKING_SPACE}
                        {trls[OpenContacts.trls.phoneVerified]}
                    </div>
                )}
                <Button
                    type={ButtonType.Button}
                    onClick={openAndMarkContacts}
                    kind={ButtonKind.Primary}
                    data-qa="buy-contacts"
                    disabled={buttonDisabled}
                    loading={buttonDisabled && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                >
                    {trls[OpenContacts.trls.content]}
                </Button>
            </div>
            {divisionId && <DivisionSelector currentDivisionId={divisionId} setDivisionId={setDivisionId} />}
        </div>
    );
};

OpenContacts.trls = {
    content: 'employer.resume.showContacts',
    phoneVerified: 'resume.phoneVerified',
};

OpenContacts.propTypes = {
    visible: PropTypes.bool,
    trls: PropTypes.object,
};

export default translation(OpenContacts);
