import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import { EmployerStateExtName } from 'lux/models/negotiationTopic.types';
import { NegotiationsHistory } from 'lux/models/negotiationsHistory';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

import styles from './history-item.less';

const TrlKeys = {
    author: 'resume.employer.history.author',
    title: 'resume.employer.history.title',
    expand: 'resume.employer.history.expand',
    collapse: 'resume.employer.history.collapse',
    loading: 'resume.employer.history.error.loading',
    [EmployerStateExtName.Response]: 'resume.employer.history.response',
    [EmployerStateExtName.Interview]: 'resume.employer.history.interview',
    [EmployerStateExtName.PhoneInterview]: 'resume.employer.history.phone_interview',
    [EmployerStateExtName.Assessment]: 'resume.employer.history.assessment',
    [EmployerStateExtName.Consider]: 'resume.employer.history.consider',
    [EmployerStateExtName.Hired]: 'resume.employer.history.hired',
    [EmployerStateExtName.Offer]: 'resume.employer.history.offer',
    [EmployerStateExtName.DiscardByEmployer]: 'resume.employer.history.discard_by_employer',
    [EmployerStateExtName.DiscardByApplicant]: 'resume.employer.history.discard_by_applicant',
    [EmployerStateExtName.DiscardNoInteraction]: 'resume.employer.history.discard_no_interaction',
    [EmployerStateExtName.DiscardVacancyClosed]: 'resume.employer.history.discard_vacancy_closed',
    [EmployerStateExtName.DiscardToOtherVacancy]: 'resume.employer.history.discard_to_other_vacancy',
    expDiscardByEmployer: 'resume.employer.history.discard_by_employer.softForm',
};

interface ItemProp {
    vacancy: NegotiationsHistory;
}

const Item: TranslatedComponent<ItemProp> = ({ vacancy, trls }) => {
    const isDiscardStatusesExp = useSelector((state) => state.isDiscardStatusesExp);
    return (
        <div className="resume-sidebar-item" data-qa="resume-history-item">
            <div className="resume-sidebar-item__text-wrapper">
                <BlokoLink
                    Element={Link}
                    kind={LinkKind.Tertiary}
                    data-qa="resume-history-item-text"
                    to={`/vacancy/${vacancy['@vacancyId']}`}
                >
                    {vacancy['@name']}
                </BlokoLink>
            </div>
            {vacancy.items.map((item, index) => (
                <div className="resume-sidebar-item__info" data-qa="resume-history-item-info" key={index}>
                    <Text importance={TextImportance.Tertiary}>
                        {isDiscardStatusesExp && (
                            <span className={styles.mainInfoWrapper}>
                                <span>&#8594;</span>
                                <span>
                                    {item['@employerStateExtName'] === EmployerStateExtName.DiscardByEmployer
                                        ? trls[TrlKeys.expDiscardByEmployer]
                                        : trls[TrlKeys[item['@employerStateExtName']]]}
                                    {', '}
                                    <span suppressHydrationWarning>{formatDate(item['@changeTime'], 'dd.MM.yy')}</span>
                                </span>
                            </span>
                        )}
                        {!isDiscardStatusesExp && (
                            <>
                                &#8594; {trls[TrlKeys[item['@employerStateExtName']]]}
                                {', '}
                                <span suppressHydrationWarning>{formatDate(item['@changeTime'], 'dd.MM.yy')}</span>
                            </>
                        )}
                        {item['@senderEmployerManagerId'] && (
                            <p className="resume-sidebar-item__text-wrapper">
                                {trls[TrlKeys.author]}
                                {': '}
                                <BlokoLink
                                    Element={Link}
                                    to={`/employer/settings?employerManagerId=${item['@senderEmployerManagerId']}`}
                                >
                                    {item['@senderFullName']}
                                </BlokoLink>
                            </p>
                        )}
                    </Text>
                </div>
            ))}
        </div>
    );
};

export default translation(Item);
