import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale } from 'bloko/blocks/button';

import { sendAnalyticsFindJobFaster } from 'Modules/ApplicantAnalytics';
import { useSelector } from 'lux/modules/useSelector';
import { ApplicantServices } from 'src/components/ApplicantServices/common';
import translation from 'src/components/translation';

const hasRenewalService = (state) => state.applicantPaymentServices.includes(ApplicantServices.ResumeRenewal);

const ResumeRenewalButton = ({ hash, trls }) => (
    <div className="resume-sidebar-link">
        {useSelector(hasRenewalService) && (
            <Button
                Element={Link}
                to={`/applicant/services/payment?from=myresume&package=basic&resumeHash=${hash}`}
                data-qa="resumeservice-button__renewresume"
                onMouseUp={sendAnalyticsFindJobFaster}
                scale={ButtonScale.Small}
                stretched={true}
            >
                {trls[ResumeRenewalButton.trls.renewResume]}
            </Button>
        )}
    </div>
);

ResumeRenewalButton.propTypes = {
    /** Хеш резюме */
    hash: PropTypes.string.isRequired,
    /** Переводы */
    trls: PropTypes.object.isRequired,
};

ResumeRenewalButton.trls = {
    renewResume: 'resumeservices.popup.renewresume',
};

export default translation(ResumeRenewalButton);
