import { useSelector } from 'lux/modules/useSelector';

const URL_BASE_TALANTIX_PROMO = 'https://talantix.ru/?utm_source=hh.ru&utm_medium=referral&utm_campaign=';
export const UTM_CAMPAIGN_FAVOURITE_RESUME = 'favourite_resume';
export const UTM_CAMPAIGN_RESUME_TRANSFER = 'resume_transfer';
export const UTM_CAMPAIGN_PRICE_OFFER = 'price_offer';

type UtmCampaign =
    | typeof UTM_CAMPAIGN_FAVOURITE_RESUME
    | typeof UTM_CAMPAIGN_RESUME_TRANSFER
    | typeof UTM_CAMPAIGN_PRICE_OFFER;

const useTalantixPromoUrl = (utmCampaign: UtmCampaign): string => {
    const hhid = useSelector((state) => state.hhid);
    const employerId = useSelector((state) => state.employerId);

    if (employerId && hhid) {
        return `${URL_BASE_TALANTIX_PROMO}${utmCampaign}&employer_id=${employerId}&hhid=${hhid}`;
    }
    return `${URL_BASE_TALANTIX_PROMO}${utmCampaign}`;
};

export default useTalantixPromoUrl;
