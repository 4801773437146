import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useFeatureEnabled from 'lux/hooks/useFeatureEnabled';
import { useIsHeadHunterPlatform } from 'lux/hooks/usePlatform';
import { JobSearchStatus } from 'lux/models/applicantUserStatuses';
import { CareerBannerPosition, CareerLinksKeys } from 'lux/models/careerPlatformState';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';
import CareerPlatformBanner from 'src/components/CareerPlatformBanner/index';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import ApplicantSkillsVerificationMethodsCard from 'src/components/ResumeView/ApplicantSkillsVerificationMethodsCard';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
// будет выпиливаться в этом квартале
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ResumeProviderSkills from 'src/pages/ResumeView/components/View/ResumeProviderSkills';
import ResumeSkillLevels from 'src/pages/ResumeView/components/View/ResumeSkillLevels/ResumeSkillLevels';

const TrlKeys = {
    title: 'rb.title.keySkills',
    titleWithCompetence: 'rb.title.keySkillsWithCompetence',
    skillVerifiedTip: 'resume.skills.verification.tip',
    keySkillsInfoTip: 'key.skills.info.tip',
    keySkillsDescription: 'key.skills.edit.description',
    keySkillsEditLink: 'key.skills.edit.link',
};

const ResumeKeySkills: TranslatedComponent = ({ trls }) => {
    const { mMain } = useGetMColumnSize();
    const isProviderSkillsEnabled = useFeatureEnabled('provider_skills');
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const applicantSkillsVerificationMethods = useSelector(({ applicantSkillsVerificationMethods }) =>
        applicantSkillsVerificationMethods.filter((method) => method.availability.status === 'AVAILABLE')
    );
    const applicantSkillsVerificationExpiring = useSelector((state) => state.applicantSkillsVerificationExpiring);
    const hasBanner = useSelector((state) => !!state.microFrontends.codePracticeBanner);
    const isPrintVersion = useSelector((state) => state.printVersion.isPrintVersion) || false;
    const linksState = useSelector((state) => state.careerPlatformLinks);

    const resume = useSelectorNonNullable((state) => state.resume);
    const resumeHash = resume.hash;
    const advancedKeySkills = resume.advancedKeySkills.value || [];
    const userId = resume.user;
    const applicantUserStatus = resume.applicantUserStatuses?.[userId];

    const showCareerBanner = Boolean(
        linksState &&
            linksState.links &&
            [JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers].includes(
                applicantUserStatus?.jobSearchStatus?.name
            )
    );

    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    const skillsToRender = {
        regular: advancedKeySkills,
    };

    return (
        <>
            {isEmployer && isProviderSkillsEnabled && <ResumeProviderSkills />}
            {skillsToRender.regular?.length > 0 && (
                <ResumeBlock
                    title={trls[TrlKeys.title]}
                    data-qa="skills-table"
                    EditLink={
                        <ResumeEditLink block="experience" field="keySkills" data-qa="resume-block-key-skills-edit" />
                    }
                >
                    <Column xs="4" s="8" m={mMain} l="12">
                        <div className="resume-block-container">
                            <>
                                {!isEmployer && (
                                    <div className="resume-skills-edit-info">
                                        <Text size={TextSize.Medium}>{trls[TrlKeys.keySkillsDescription]}</Text>
                                        <Link
                                            to={`/applicant/resumes/edit/skills_levels?resume=${resumeHash}&withBacklink=true`}
                                        >
                                            {trls[TrlKeys.keySkillsEditLink]}
                                        </Link>
                                    </div>
                                )}
                                <ResumeSkillLevels skills={skillsToRender.regular} />
                                {hasBanner && !isPrintVersion && (
                                    <>
                                        <VSpacing base={4} />
                                        <ContainerForMicroFrontend place={'codePracticeBanner'} />
                                    </>
                                )}
                                {((isHeadHunterPlatform && applicantSkillsVerificationMethods?.length > 0) ||
                                    applicantSkillsVerificationExpiring.length > 0) && (
                                    <div className="noprint">
                                        <VSpacing base={6} />
                                        <ApplicantSkillsVerificationMethodsCard
                                            applicantSkillsVerificationMethods={applicantSkillsVerificationMethods}
                                            applicantSkillsVerificationExpiring={applicantSkillsVerificationExpiring}
                                        />
                                    </div>
                                )}
                                {showCareerBanner && !hasBanner && (
                                    <>
                                        <VSpacing base={6} />
                                        <CareerPlatformBanner
                                            position={CareerBannerPosition.Resume}
                                            destination={CareerLinksKeys.Courses}
                                        />
                                    </>
                                )}
                            </>
                        </div>
                    </Column>
                </ResumeBlock>
            )}
        </>
    );
};

export default translation(ResumeKeySkills);
