import { useState, useRef, useEffect } from 'react';

import { Tooltip } from '@hh.ru/magritte-ui';
import DropInfo, { InfoPlacement } from 'bloko/blocks/drop/Info';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices from 'Modules/Notices';
import TooltipHost from 'src/components/TooltipHost';
import translation from 'src/components/translation';

import styles from './employer-zp-onboarding.less';

export const EMPLOYER_ZP_ONBOARDING_VACANCY_CREATE_WORK_MODE_TIP = 'employerZpOnboardingVacancyCreateWorkModeTip';
export const EMPLOYER_ZP_ONBOARDING_RESUME_VIEW_COMMENTS_TIP = 'employerZpOnboardingResumeViewCommentsTip';

export enum TipTypes {
    VacancyCreateWorkMode = 'vacancyCreateWorkMode',
    ResumeViewComments = 'resumeViewComments',
}

const TrlKeys = {
    workModeDropInfoText: 'employer.zpOnboardingModal.workModeDropInfoText',
    resumeViewCommentsDropInfoText: 'employer.zpOnboardingModal.resumeViewCommentsDropInfoText',
};

const dropInfoConfig = {
    [TipTypes.VacancyCreateWorkMode]: {
        dropPlacement: InfoPlacement.RightStart,
        noticeName: EMPLOYER_ZP_ONBOARDING_VACANCY_CREATE_WORK_MODE_TIP,
        dropInfoText: TrlKeys.workModeDropInfoText,
        needHost: true,
    },
    [TipTypes.ResumeViewComments]: {
        dropPlacement: InfoPlacement.TopStart,
        noticeName: EMPLOYER_ZP_ONBOARDING_RESUME_VIEW_COMMENTS_TIP,
        dropInfoText: TrlKeys.resumeViewCommentsDropInfoText,
        needHost: false,
    },
};

const TOOLTIP_CONFIG = {
    [TipTypes.VacancyCreateWorkMode]: {
        placement: 'right-top',
        dropInfoText: TrlKeys.workModeDropInfoText,
        noticeName: EMPLOYER_ZP_ONBOARDING_VACANCY_CREATE_WORK_MODE_TIP,
        needHost: true,
    },
    [TipTypes.ResumeViewComments]: {
        placement: 'right-top',
        noticeName: EMPLOYER_ZP_ONBOARDING_RESUME_VIEW_COMMENTS_TIP,
        dropInfoText: TrlKeys.resumeViewCommentsDropInfoText,
        needHost: false,
    },
} as const;

interface EmployerZpOnboardingTitleDropInfoProps {
    tipType: TipTypes;
    redesign?: boolean;
}

const EmployerZpOnboardingTitleDropInfo: TranslatedComponent<EmployerZpOnboardingTitleDropInfoProps> = ({
    trls,
    tipType,
    children,
    redesign = false,
}) => {
    const [showInfoTip, setShowInfoTip] = useState(true);
    const breakpoint = useBreakpoint();
    const isAllowedScreen = ![Breakpoint.XS, Breakpoint.S].includes(breakpoint);
    const host = useRef<HTMLSpanElement>(null);
    const activatorRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (!isAllowedScreen) {
            const { noticeName } = TOOLTIP_CONFIG[tipType];
            // помечаем прочитанным, чтобы не блокировать показ остальных инфотипов на странице
            Notices.markAsViewed(noticeName);
        }
    }, [isAllowedScreen, tipType]);

    if (!isAllowedScreen || !tipType) {
        return <>{children}</>;
    }

    if (redesign) {
        const { placement, noticeName, dropInfoText } = TOOLTIP_CONFIG[tipType];

        return (
            <TooltipHost tipVisible={showInfoTip && isAllowedScreen}>
                {(host, tipVisible) => (
                    <>
                        <span ref={activatorRef}>{children}</span>
                        <Tooltip
                            placement={placement}
                            visible={tipVisible}
                            activatorRef={activatorRef}
                            onClose={() => {
                                Notices.markAsViewed(noticeName);
                                setShowInfoTip(false);
                            }}
                            showClose
                            closeByClickOutside={false}
                            aria-label-close="close"
                            widthEqualToActivator={false}
                        >
                            <div className={styles.employerZpOnboardingTooltip}>{trls[dropInfoText]}</div>
                        </Tooltip>
                    </>
                )}
            </TooltipHost>
        );
    }

    const { dropPlacement, noticeName, dropInfoText, needHost } = dropInfoConfig[tipType];

    return (
        <DropInfo
            placement={dropPlacement}
            show={showInfoTip && isAllowedScreen}
            showCloseButton
            closeByClickOutside={false}
            host={needHost ? host.current : null}
            onClose={() => {
                Notices.markAsViewed(noticeName);
                setShowInfoTip(false);
            }}
            render={() => <div>{trls[dropInfoText]}</div>}
        >
            <span ref={host}>{children}</span>
        </DropInfo>
    );
};

export default translation(EmployerZpOnboardingTitleDropInfo);
