import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import { useProfile } from 'lux/hooks/useProfile';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';

const ResumeAdditional = ({ isEmpty, citizenship, workTicket, travelTime, trls }) => {
    const { mMain } = useGetMColumnSize();
    if (useProfile()) {
        return null;
    }
    if (isEmpty) {
        return (
            <Column xs="4" s="8" m={mMain} l="12">
                <ResumeEmptyBlock block="additional" />
            </Column>
        );
    }
    return (
        <ResumeBlock
            title={trls[ResumeAdditional.trls.title]}
            EditLink={<ResumeEditLink block="additional" data-qa="resume-block-additional-edit" />}
            data-qa="resume-block-additional"
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    {citizenship.length > 0 && (
                        <p>
                            {trls[ResumeAdditional.trls.citizenship]}
                            {': '}
                            {citizenship.map((citizenshipItem) => citizenshipItem.title).join(', ')}
                        </p>
                    )}
                    {workTicket.length > 0 && (
                        <p>
                            {trls[ResumeAdditional.trls.workTicket]}
                            {': '}
                            {workTicket.map((workTicketItem) => workTicketItem.title).join(', ')}
                        </p>
                    )}
                    {travelTime !== '' && (
                        <p>
                            {trls[ResumeAdditional.trls.travelTime]}
                            {': '}
                            <span className="resume-block-travel-time">
                                {trls[ResumeAdditional.trls[`traveltime.${travelTime}`]]}
                            </span>
                        </p>
                    )}
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumeAdditional.propTypes = {
    isEmpty: PropTypes.bool,
    citizenship: PropTypes.array.isRequired,
    workTicket: PropTypes.array.isRequired,
    travelTime: PropTypes.string.isRequired,
    trls: PropTypes.object,
};

ResumeAdditional.trls = {
    title: 'rb.title.additional',
    citizenship: 'rb.title.citizenship',
    workTicket: 'rb.title.workTicket',
    travelTime: 'rb.title.travelTime',
    'traveltime.less_than_hour': 'traveltime.less_than_hour',
    'traveltime.any': 'traveltime.any',
    'traveltime.from_hour_to_one_and_half': 'traveltime.from_hour_to_one_and_half',
};

export default connect((state) => ({
    isEmpty: state.resume?.emptyBlocks.includes('additional'),
    citizenship: state.resume?.citizenship.value,
    workTicket: state.resume?.workTicket.value,
    travelTime: state.resume?.travelTime.value,
}))(translation(ResumeAdditional));
