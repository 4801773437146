import PropTypes from 'prop-types';

import Button from 'bloko/blocks/button';
import { HoverTip } from 'bloko/blocks/drop';
import { TrashScaleSmall, IconColor } from 'bloko/blocks/icon';

import useIsClient from 'lux/hooks/useIsClient';
import ResumeDelete from 'src/components/ResumeDelete';
import translation from 'src/components/translation';

const ButtonDelete = ({ hash, trls }) => {
    const isClient = useIsClient();

    return (
        <ResumeDelete
            hash={hash}
            from="resume"
            render={(handleClick) => (
                <HoverTip host={isClient ? document.body : null} render={() => trls[ButtonDelete.trls.delete]}>
                    <Button
                        data-qa="resume-delete"
                        icon={<TrashScaleSmall initial={IconColor.Gray50} />}
                        onClick={handleClick}
                        aria-label={trls[ButtonDelete.trls.delete]}
                    />
                </HoverTip>
            )}
        />
    );
};

ButtonDelete.propTypes = {
    hash: PropTypes.string.isRequired,
    trls: PropTypes.object.isRequired,
};

ButtonDelete.trls = {
    delete: 'topbuttons.resume.delete',
};

export default translation(ButtonDelete);
