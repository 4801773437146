import { VerifiedSkill } from 'lux/models/resume/resumeVerifiedSkills.types';

const isHaveCategory = (array: VerifiedSkill[], skillName: string): boolean => {
    if (!array) {
        return false;
    }

    return array.some((skill: VerifiedSkill) => skill.category === skillName);
};
const getVerifiedSkillCategory = (skills: VerifiedSkill[]): string | null => {
    const isCategoriesHaveSkills = isHaveCategory(skills, 'SKILL');
    const isCategoriesHaveLanguages = isHaveCategory(skills, 'LANG');

    if (isCategoriesHaveSkills) {
        return 'SKILL';
    }
    if (isCategoriesHaveLanguages && !isCategoriesHaveSkills) {
        return 'LANG';
    }
    return null;
};

export { getVerifiedSkillCategory };
